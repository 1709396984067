import React from 'react';
import type { TimeRangePickerProps } from 'antd';
import { DatePicker, Space } from 'antd';
import dayjs from 'dayjs';
import type { Dayjs } from 'dayjs';

const { RangePicker } = DatePicker;

const rangePresets: TimeRangePickerProps['presets'] = [
	{ label: 'Este mês', value: [dayjs().startOf('month'), dayjs().endOf('month')] },
	{ label: 'Últimos 30 dias', value: [dayjs().subtract(30, 'day'), dayjs()] },
	{ label: 'Últimos 6 meses', value: [dayjs().subtract(6, 'month'), dayjs()] },
	{ label: 'Este ano', value: [dayjs().startOf('year'), dayjs().endOf('year')] },
];

interface RangeDatePickerProps {
	intervaloDataSelecionado: [Dayjs | null, Dayjs | null];
	onDateRangeChange: (dates: [Dayjs | null, Dayjs | null] | null) => void;
}

const RangeDatePicker: React.FC<RangeDatePickerProps> = ({ intervaloDataSelecionado, onDateRangeChange }) => {
	return (
		<Space direction='vertical' size={12}>
			<RangePicker
				presets={rangePresets}
				format='DD/MM/YYYY'
				value={intervaloDataSelecionado}
				onChange={(dates) => onDateRangeChange(dates)}
			/>
		</Space>
	);
};

export default RangeDatePicker;
