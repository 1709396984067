import { Flex, Row, Typography, Button, Select, Icons } from '@jcm/design-system';
import React, { useEffect, useState } from 'react';
import { Control, Controller } from 'react-hook-form';
import { FiltrosObrigacoesEnum } from '../../../ts/enums/FiltrosObrigacoesEnum';
import estilo from './index.module.scss';
import { TOrigem } from '../../../ts/types/Origem';
import { useGovernancaContext } from '../../../../../../context/GovernancaContext';
import ObrigacoesService from '../../../services/Obrigacoes/Obrigacoes.service';
import { ClearOutlined } from '@ant-design/icons';
import { Input } from 'antd';
const { TextArea } = Input;

interface FiltrosObrigacoesFormValues {
	[FiltrosObrigacoesEnum.Origem]: string | number;
	[FiltrosObrigacoesEnum.Responsavel]: string | number;
	[FiltrosObrigacoesEnum.Aprovador]: string | number;
	[FiltrosObrigacoesEnum.Situacao]: string;
	[FiltrosObrigacoesEnum.Tipo]: string;
	[FiltrosObrigacoesEnum.Descricao]: string;
}

interface FiltrosObrigacoesProps {
	aoAplicarFiltros: (filtrosSelecionados: Record<string, string | number>) => void;
	listaResponsaveis: { funcionarioId: number; nome: string }[];
	listaAprovadores: { funcionarioId: number; nome: string }[];
	control: Control<FiltrosObrigacoesFormValues>;
	loading: boolean;
}

const Filtros: React.FC<FiltrosObrigacoesProps> = ({
	aoAplicarFiltros,
	listaResponsaveis,
	listaAprovadores,
	control,
}) => {
	const [origens, setOrigens] = useState<TOrigem[]>([]);
	const [carregandoOrigens, setCarregandoOrigens] = useState(true);
	const { empresaAtiva } = useGovernancaContext();
	const [descricaoTemporaria, setDescricaoTemporaria] = useState<string>('');
	const [carregamentoBotoes, setCarregamentoBotoes] = useState<{ [key: string]: boolean }>({
		limpar: false,
		aplicar: false,
	});
	const obrigacoesService = new ObrigacoesService();

	const limparFiltros = () => {
		setCarregamentoBotoes((prevState) => ({ ...prevState, limpar: true }));

		requestAnimationFrame(() => {
			setDescricaoTemporaria('');
			setFiltrosSelecionados({
				[FiltrosObrigacoesEnum.Origem]: '',
				[FiltrosObrigacoesEnum.Responsavel]: '',
				[FiltrosObrigacoesEnum.Aprovador]: '',
				[FiltrosObrigacoesEnum.Situacao]: '',
				[FiltrosObrigacoesEnum.Tipo]: '',
				[FiltrosObrigacoesEnum.Descricao]: '',
			});

			aoAplicarFiltros({
				[FiltrosObrigacoesEnum.Origem]: '',
				[FiltrosObrigacoesEnum.Responsavel]: '',
				[FiltrosObrigacoesEnum.Aprovador]: '',
				[FiltrosObrigacoesEnum.Situacao]: '',
				[FiltrosObrigacoesEnum.Tipo]: '',
				[FiltrosObrigacoesEnum.Descricao]: '',
			});
			setCarregamentoBotoes((prevState) => ({ ...prevState, limpar: false }));
		});
	};

	const aplicarFiltros = () => {
		setCarregamentoBotoes((prevState) => ({ ...prevState, aplicar: true }));

		requestAnimationFrame(() => {
			aoAplicarFiltros(filtrosSelecionados);
			setCarregamentoBotoes((prevState) => ({ ...prevState, aplicar: false }));
		});
	};

	const todosFiltrosVazios = () => {
		return Object.values(filtrosSelecionados).every((valor) => valor === '');
	};

	const [filtrosSelecionados, setFiltrosSelecionados] = useState<Record<string, string | number>>({
		[FiltrosObrigacoesEnum.Origem]: '',
		[FiltrosObrigacoesEnum.Responsavel]: '',
		[FiltrosObrigacoesEnum.Aprovador]: '',
		[FiltrosObrigacoesEnum.Situacao]: '',
		[FiltrosObrigacoesEnum.Tipo]: '',
		[FiltrosObrigacoesEnum.Descricao]: '',
	});

	const alterarDescricao = (evento: React.ChangeEvent<HTMLTextAreaElement>) => {
		alterarFiltro(FiltrosObrigacoesEnum.Descricao, evento.target.value);
	};

	const alterarFiltro = (chave: FiltrosObrigacoesEnum, valor: string | number) => {
		const novosFiltros = { ...filtrosSelecionados };

		if (chave === FiltrosObrigacoesEnum.Descricao) {
			novosFiltros[FiltrosObrigacoesEnum.Nome] = valor;
			novosFiltros[FiltrosObrigacoesEnum.Descricao] = valor;
		} else {
			novosFiltros[chave] = valor;
		}
		setFiltrosSelecionados(novosFiltros);
	};

	async function buscarOrigens(): Promise<void> {
		if (empresaAtiva !== null) {
			try {
				const respostaOrigens = await obrigacoesService.getAllOrigem(empresaAtiva);
				setOrigens(respostaOrigens);
			} catch (erro) {
				console.error('Erro ao buscar origens:', erro);
			} finally {
				setCarregandoOrigens(false);
			}
		}
	}

	useEffect(() => {
		if (empresaAtiva) {
			buscarOrigens();
		}
	}, [empresaAtiva]);

	return (
		<div className={estilo.filtroContainer} id='filtroContainer' style={{ overflowY: 'auto' }}>
			<Row style={{ gap: '1rem' }}>
				<Flex vertical className={estilo.campoFiltro}>
					<Typography.Label>Descrição</Typography.Label>
					<TextArea
						id='descricao'
						placeholder='Digite a descrição'
						className={estilo.campoFiltro}
						variant='filled'
						value={filtrosSelecionados[FiltrosObrigacoesEnum.Descricao] || descricaoTemporaria}
						onChange={alterarDescricao}
					/>
				</Flex>
				<Controller
					name={FiltrosObrigacoesEnum.Responsavel}
					control={control}
					render={({ field }) => (
						<Select
							showSearch
							{...field}
							label='Responsável'
							placeholder='Selecione o Responsável'
							options={listaResponsaveis.map((responsavel) => ({
								label: responsavel.nome,
								value: responsavel.funcionarioId,
							}))}
							className={estilo.campoFiltro}
							value={filtrosSelecionados[FiltrosObrigacoesEnum.Responsavel] || undefined}
							allowClear={true}
							onChange={(valor) => alterarFiltro(FiltrosObrigacoesEnum.Responsavel, valor)}
							filterOption={(input, option) =>
								(option?.label ?? '').toString().toLowerCase().includes(input.toLowerCase())
							}
						/>
					)}
				/>
				<Controller
					name={FiltrosObrigacoesEnum.Aprovador}
					control={control}
					render={({ field }) => (
						<Select
							showSearch
							{...field}
							label='Aprovador'
							placeholder='Selecione o Aprovador'
							options={listaAprovadores.map((responsavel) => ({
								label: responsavel.nome,
								value: responsavel.funcionarioId,
							}))}
							className={estilo.campoFiltro}
							value={filtrosSelecionados[FiltrosObrigacoesEnum.Aprovador] || undefined}
							allowClear={true}
							onChange={(valor) => alterarFiltro(FiltrosObrigacoesEnum.Aprovador, valor)}
							filterOption={(input, option) =>
								(option?.label ?? '').toString().toLowerCase().includes(input.toLowerCase())
							}
						/>
					)}
				/>
				<Controller
					name={FiltrosObrigacoesEnum.Origem}
					control={control}
					render={({ field }) => (
						<Select
							showSearch
							{...field}
							label='Origem'
							allowClear={true}
							placeholder='Selecione a Origem'
							options={origens.map((origem) => ({ label: origem.nomeOrigem, value: origem.origemId }))}
							loading={carregandoOrigens}
							className={estilo.campoFiltro}
							onChange={(valor) => alterarFiltro(FiltrosObrigacoesEnum.Origem, valor)}
							value={filtrosSelecionados[FiltrosObrigacoesEnum.Origem] || undefined}
							filterOption={(input, option) =>
								(option?.label ?? '').toString().toLowerCase().includes(input.toLowerCase())
							}
						/>
					)}
				/>
				<Controller
					name={FiltrosObrigacoesEnum.Tipo}
					control={control}
					render={({ field }) => (
						<Select
							showSearch
							{...field}
							allowClear={true}
							label='Tipo'
							placeholder='Selecione o Tipo'
							className={estilo.campoFiltro}
							options={[
								{ label: 'Legal', value: 'legal' },
								{ label: 'Própria', value: 'propria' },
							]}
							value={filtrosSelecionados[FiltrosObrigacoesEnum.Tipo] || undefined}
							onChange={(valor) => alterarFiltro(FiltrosObrigacoesEnum.Tipo, valor)}
						/>
					)}
				/>
			</Row>
			<Flex className={estilo.acoesFiltro}>
				<Button
					type='outlined'
					variant='error'
					icon={<ClearOutlined />}
					onClick={limparFiltros}
					className={estilo.aplicarFiltrosButton}
					loading={carregamentoBotoes.limpar}
				>
					Limpar
				</Button>
				<Button
					type='outlined'
					variant='default'
					onClick={aplicarFiltros}
					className={estilo.aplicarFiltrosButton}
					icon={<Icons.FilterAlt style={{ fontSize: '20px' }} />}
					disabled={todosFiltrosVazios()}
					loading={carregamentoBotoes.aplicar}
				>
					Aplicar
				</Button>
			</Flex>
		</div>
	);
};
export default Filtros;
