import { Button, Col, Flex, Row, Switch, Typography } from '@jcm/design-system';
import React, { useState } from 'react';
import estilo from './index.module.scss';
import ModalContainer from '../../../components/modalContainer/ModalContainer';
import FormObrigacoes from '../FormObrigacoes/FormObrigacoes';
import { TObrigacao } from '../../../ts/types/Obrigacoes';
import usePermissao from '../../../hooks';
import { Permissoes } from '../../../ts/enums/PermissoesEnum';

interface BarraFerramentasObrigacoesProps {
	empresaId: number | string;
	onObrigacaoChange: (obrigacaoAtualizada: TObrigacao) => void;
	showCompleted: boolean;
	loading: boolean;
	onSwitchChange: (checked: boolean) => void;
}

const BarraFerramentasObrigacoes: React.FC<BarraFerramentasObrigacoesProps> = ({
	empresaId,
	onObrigacaoChange,
	showCompleted,
	loading,
	onSwitchChange,
}) => {
	const [isModalVisible, setModalVisible] = useState(false);
	const [modalContent, setModalContent] = useState<React.ReactNode>(null);
	const temPermissaoAdministrador = usePermissao([Permissoes.AdministradorEmpresa, Permissoes.AdministradorSistema]);
	const isDisabled = loading;

	const showModal = (content: React.ReactNode) => {
		setModalContent(content);
		setModalVisible(true);
	};

	const handleCloseModal = () => {
		setModalVisible(false);
	};

	return (
		<>
			<Row justify='space-between' align='middle' className={estilo.barraFerramentas}>
				<Flex align='center' gap={8} className={estilo.MinhasObrigacoesTab}>
					<Typography.Body size='medium' strong>
						Minhas Obrigações
					</Typography.Body>

					<Switch
						disabled={!temPermissaoAdministrador || isDisabled}
						onChange={(e) => onSwitchChange(e)}
						checked={showCompleted}
					/>
				</Flex>

				<Col xl={6} xxl={4} className={estilo.colNovaObrigacao}>
					{!loading && (
						<Flex justify='flex-end'>
							<Button
								type='filled-tonal'
								variant='default'
								className={estilo.novaObrigacao}
								onClick={() =>
									showModal(
										<FormObrigacoes
											isEdit={false}
											initialData={{}}
											onSave={(data) => console.log('Data Saved:', data)}
											onClose={handleCloseModal}
											empresaId={empresaId}
											onObrigacaoChange={onObrigacaoChange}
										/>,
									)
								}
							>
								Nova Obrigação
							</Button>
						</Flex>
					)}
				</Col>

				<ModalContainer
					isVisible={isModalVisible}
					onClose={handleCloseModal}
					className={`form-obrigacao ${estilo.modalFormContainer}`}
					key={isModalVisible ? 'visible' : 'hidden'}
				>
					{modalContent}
				</ModalContainer>
			</Row>
		</>
	);
};

export default BarraFerramentasObrigacoes;
