/* eslint-disable @typescript-eslint/no-explicit-any */
import dayjs, { Dayjs } from 'dayjs';
import { Button } from 'antd';
import { TextInput, Icons, Flex, RangePicker } from '@jcm/design-system';
import { TObrigacao } from '../../ts/types/Obrigacoes';
import { Key } from 'antd/es/table/interface';

export const createTextFilter =
	(placeholder: string) =>
	({
		setSelectedKeys,
		selectedKeys,
		confirm,
	}: {
		selectedKeys: any[];
		confirm: () => void;
		setSelectedKeys: (s: any[]) => void;
	}) => (
		<div style={{ padding: 8 }}>
			<TextInput
				type='text'
				variant='outlined'
				placeholder={placeholder}
				value={selectedKeys[0]}
				onChange={(e) => {
					setSelectedKeys(e.target.value ? [e.target.value] : []);
				}}
				onPressEnter={() => confirm()}
				style={{ marginBottom: 8, display: 'block' }}
			/>
			<Flex style={{ justifyContent: 'space-between' }}>
				<Button
					disabled={!selectedKeys[0]}
					onClick={() => {
						setSelectedKeys([]);
						confirm();
					}}
					size='small'
					type='link'
					style={{ width: 90 }}
				>
					Limpar
				</Button>
				<Button type='primary' onClick={() => confirm()} icon={<Icons.Search />} size='small' style={{ width: 90 }}>
					Filtrar
				</Button>
			</Flex>
		</div>
	);

//Logica do filtro de texto
export const createTextOnFilter = (dataIndex: string) => (value: any, record: any) => {
	const recordValue = record[dataIndex];
	return recordValue ? recordValue.toString().toLowerCase().includes(value.toLowerCase()) : false;
};

// Filtro de Data
export const createDateFilter =
	() =>
	({
		setSelectedKeys,
		selectedKeys,
		confirm,
		clearFilters,
	}: {
		setSelectedKeys: (keys: any[]) => void;
		selectedKeys: any[];
		confirm: () => void;
		clearFilters?: () => void;
	}) => (
		<div style={{ padding: 8 }}>
			<RangePicker
				format='DD/MM/YYYY'
				value={
					selectedKeys[0] && selectedKeys[0].length === 2
						? ([dayjs(selectedKeys[0][0], 'YYYY-MM-DD'), dayjs(selectedKeys[0][1], 'YYYY-MM-DD')] as [Dayjs, Dayjs])
						: null
				}
				onChange={(dates: [Dayjs | null, Dayjs | null] | null) => {
					if (dates && dates[0] && dates[1]) {
						setSelectedKeys([[dates[0].format('YYYY-MM-DD'), dates[1].format('YYYY-MM-DD')]]);
					} else {
						setSelectedKeys([]);
					}
				}}
				style={{ marginBottom: 8, display: 'block' }}
			/>
			<Flex style={{ justifyContent: 'space-between' }}>
				<Button
					disabled={!selectedKeys.length}
					onClick={() => {
						if (clearFilters) clearFilters();
						confirm();
					}}
					size='small'
					type='link'
					style={{ width: 90 }}
				>
					Limpar
				</Button>
				<Button type='primary' onClick={() => confirm()} icon={<Icons.Search />} size='small' style={{ width: 90 }}>
					Filtrar
				</Button>
			</Flex>
		</div>
	);

// Lógica do filtro de data
export const createDateOnFilter =
	(dataField: keyof TObrigacao) =>
	(value: Key | boolean | [string, string], record: TObrigacao): boolean => {
		if (!Array.isArray(value) || value.length !== 2) return true; // Garantir que value é um array com 2 elementos
		const [start, end] = value as [string, string]; // Certificando-se de que value é tratado como um array de strings
		const recordDateValue = record[dataField];

		if (!recordDateValue) return false;

		const recordDate = dayjs(record[dataField], 'YYYY-MM-DD');
		return recordDate.isBetween(dayjs(start), dayjs(end), 'day', '[]');
	};

export const createFilterList = (filterOptions: string[]) => {
	return filterOptions.map((option) => ({
		text: option,
		value: option,
	}));
};
