import { Box, IconButton, MenuItem, Select } from '@mui/material';
import ArrowLeft from '@mui/icons-material/ArrowLeft';
import ArrowRight from '@mui/icons-material/ArrowRight';
import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';
import estilos from './index.module.scss';
import { Popover } from 'antd';

dayjs.locale('pt-br');

function CabecalhoCalendario({
	currentMonth,
	onMonthChange,
	selectedDate,
	handleClearSelection,
	diaFiltroSelecionado,
}) {
	const handlePreviousMonth = () => onMonthChange(currentMonth.subtract(1, 'month'));
	const handleNextMonth = () => onMonthChange(currentMonth.add(1, 'month'));

	const handleMonthChange = (event) => {
		const newMonth = parseInt(event.target.value, 10);
		const newDate = currentMonth.month(newMonth);
		onMonthChange(newDate);
	};

	const handleYearChange = (event) => {
		const newYear = parseInt(event.target.value, 10);
		const newDate = currentMonth.year(newYear);
		onMonthChange(newDate);
	};

	// Lista de meses abreviados
	const months = dayjs.months();
	const years = Array.from(new Array(11), (_, index) => dayjs().year() - 5 + index);

	return (
		<Box className={estilos.cabecalhoCalendario}>
			<IconButton
				className={estilos.iconeCabecalhoCalendario}
				onClick={handlePreviousMonth}
				sx={{
					fontSize: 'var(--md-sys-typescale-label-medium-font-size)',
					padding: '2px',
				}}
			>
				<ArrowLeft />
			</IconButton>
			<Select
				value={currentMonth.month()}
				onChange={handleMonthChange}
				className={estilos.selectMesCabecalhoCalendario}
				sx={{
					'& .MuiOutlinedInput-notchedOutline': {
						border: 'none',
					},
					boxShadow: 'none',
					backgroundColor: 'transparent',
					borderRadius: 0,
					padding: 0,
					fontFamily: 'var(--md-sys-typescale-label-medium-font-family-name)',
					fontSize: 'var(--md-sys-typescale-label-medium-font-size)',
				}}
			>
				{months.map((month, index) => (
					<MenuItem key={index} value={index}>
						{month.charAt(0).toUpperCase() + month.slice(1)}
					</MenuItem>
				))}
			</Select>
			<Select
				value={currentMonth.year()}
				onChange={handleYearChange}
				className={estilos.selectAnoCabecalhoCalendario}
				sx={{
					'& .MuiOutlinedInput-notchedOutline': {
						border: 'none',
					},
					boxShadow: 'none',
					backgroundColor: 'transparent',
					borderRadius: 0,
					padding: 0,
					fontFamily: 'var(--md-sys-typescale-label-medium-font-family-name)',
					fontSize: 'var(--md-sys-typescale-label-medium-font-size)',
				}}
			>
				{years.map((year) => (
					<MenuItem key={year} value={year}>
						{year}
					</MenuItem>
				))}
			</Select>
			{diaFiltroSelecionado && (
				<Popover content='Limpar data selecionada'>
					<span
						onClick={handleClearSelection}
						style={{ cursor: 'pointer', width: '1.2rem !important', fontSize: '1.2rem' }}
						className='material-symbols-outlined'
					>
						event_busy
					</span>
				</Popover>
			)}

			<IconButton
				className={estilos.iconeCabecalhoCalendario}
				onClick={handleNextMonth}
				sx={{
					fontSize: 'var(--md-sys-typescale-label-medium-font-size)',
					padding: '2px',
				}}
			>
				<ArrowRight />
			</IconButton>
		</Box>
	);
}

export default CabecalhoCalendario;
