import React from 'react';
import { Route, Routes } from 'react-router-dom';
import HomeModuloObrigacoes from '../pages/Home';
import { NotFound } from '@jcm/design-system';
import Obrigacoes from '../pages/Obrigacoes';
import Usuarios from '../pages/AreaAdm/Usuarios';
import Relatorios from '../pages/Relatorios/relatorioObrigacao';
import Origem from '../pages/AreaAdm/Origem';
import OperacoesEmMassa from '../pages/OperacoesEmMassa';
import RegistrosAtividades from '../pages/AreaAdm/Logs';

export default function ModuloObrigacoesAppRoute() {
	return (
		<Routes>
			<Route path='/principal' index element={<HomeModuloObrigacoes />} />
			<Route path='/listagem' element={<Obrigacoes />} />
			<Route path='/usuarios' element={<Usuarios />} />
			<Route path='/relatorios' element={<Relatorios />} />
			<Route path='/origem' element={<Origem />} />
			<Route path='/registros-atividades' element={<RegistrosAtividades />} />
			<Route path='/operacoes-em-massa' element={<OperacoesEmMassa />} />
			<Route path='*' element={<NotFound />} />
		</Routes>
	);
}
