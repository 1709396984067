import React, { useMemo } from 'react';
import { ITabsProps, Tabs } from '@jcm/design-system';
import TabMenuObrigacoes from './TabMenuObrigacoes';

type ObrigacoesTabMenuProps = {
	concluidasNoPrazo: number;
	concluidasComAtraso: number;
	vencidasNaoConcluidas: number;
	naoConcluidasNoPrazo: number;
	aguardandoAprovacao: number;
	todasObrigacoes: number;
	aprovadas: number;
	activeTabKey: string;
	onTabChange: (tabKey: string) => void;
	onSwitchChange: (checked: boolean) => void;
	showCompleted: boolean;
	loading: boolean;
};

const ObrigacoesTabMenu: React.FC<ObrigacoesTabMenuProps> = (props) => {
	const tituloTodasObrigacoes = props.showCompleted ? 'Minhas Obrigações' : 'Todas Obrigações';

	const isDisabled = props.loading;

	const paginas: ITabsProps['items'] = useMemo(
		() => [
			{
				key: 'todasObrigacoes',
				label: (
					<TabMenuObrigacoes
						tituloPagina={tituloTodasObrigacoes}
						rotuloContagemObrigações='todos'
						quantidade={props.todasObrigacoes}
					/>
				),
				children: null,
				disabled: isDisabled,
			},
			{
				key: 'obrigacoesVencidas',
				label: (
					<TabMenuObrigacoes
						tituloPagina='Vencidas'
						rotuloContagemObrigações='vencidos'
						quantidade={props.vencidasNaoConcluidas}
					/>
				),
				children: null,
				disabled: isDisabled,
			},
			{
				key: 'obrigacoesAguardandoAprovacoes',
				label: (
					<TabMenuObrigacoes
						tituloPagina='Aguardando Aprovação'
						rotuloContagemObrigações='aguardando'
						quantidade={props.aguardandoAprovacao}
					/>
				),
				children: null,
				disabled: isDisabled,
			},
			{
				key: 'obrigacoesNoPrazo',
				label: (
					<TabMenuObrigacoes
						tituloPagina='No prazo'
						rotuloContagemObrigações='noPrazo'
						quantidade={props.naoConcluidasNoPrazo}
					/>
				),
				children: null,
				disabled: isDisabled,
			},
			{
				key: 'obrigacoesConcluidas',
				label: (
					<TabMenuObrigacoes
						tituloPagina='Concluídas'
						rotuloContagemObrigações='concluidas'
						quantidade={props.aprovadas}
					/>
				),
				children: null,
				disabled: isDisabled,
			},
		],
		[props],
	);

	const handleTabChange = (key: string) => {
		props.onTabChange(key);
	};

	return <Tabs items={paginas} activeKey={props.activeTabKey} onChange={handleTabChange} />;
};

export default ObrigacoesTabMenu;
