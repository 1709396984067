import React, { useState } from 'react';
import { Avatar, Box, IconButton } from '@mui/material';
import { Popover } from 'antd';
import CloseIcon from '@mui/icons-material/Close';
import styles from './index.module.scss';
import { Typography } from '@jcm/design-system';

interface AvatarGroupComponentProps {
    title: string;
    members: { id: number; name: string; nomeCompleto: string }[];
    color?: string;
    bgColor?: string;
    onRemove?: (id: number) => void;
    required?: boolean;
    estadoStatusAprovada?: number;
    disabled?: boolean;
    tipo?: 'Responsáveis' | 'Aprovadores';
}

const AvatarGroupComponent: React.FC<AvatarGroupComponentProps> = ({
    title,
    members,
    bgColor,
    color,
    onRemove,
    required = false,
    estadoStatusAprovada,
    disabled,
    tipo,
}) => {
    const [open, setOpen] = useState(false);
    const displayMembers = members.slice(0, 2);
    const remainingMembers = members.slice(2);
    const remainingCount = remainingMembers.length;

    const handleOpenChange = (newOpen: boolean) => {
        setOpen(newOpen);
    };

    return (
        <Box className={styles.avatarGroupContainer}>
            <Typography.Body>
                {title} {required && <span className={styles.required}>*</span>}
            </Typography.Body>
            <Box className={styles.avatarGroup}>
                {displayMembers.map((member) => (
                    <Box key={member.id} className={styles.avatarBox}>
                        <Popover
                            content={<span>{member.nomeCompleto}</span>}
                            trigger="hover"
                            placement="bottom"
                        >
                            <Avatar
                                className={`${styles.avatars} ${styles.avatarCustom} ${styles.bgAvatar}`}
                            >
                                {member.name}
                            </Avatar>
                        </Popover>
                        {onRemove && !disabled && (
                            <IconButton
                                size="small"
                                className={styles.removeButton}
                                onClick={() => onRemove(member.id)}
                            >
                                <CloseIcon fontSize="small" />
                            </IconButton>
                        )}
                    </Box>
                ))}

                {remainingCount > 0 && (
                    <Popover
                        content={
                            <div className={styles.popoverContent}>
                                {members.map((member) => (
                                    <div key={member.id} className={styles.memberItem}>
                                        <span>
                                            {member.nomeCompleto}
                                            {onRemove && !disabled && (
                                                <IconButton
                                                    size="small"
                                                    className={styles.closeButton}
                                                    onClick={() => onRemove(member.id)}
                                                >
                                                    <CloseIcon fontSize="small" />
                                                </IconButton>
                                            )}
                                        </span>
                                    </div>
                                ))}
                            </div>
                        }
                        title={`Todos os ${tipo}`}
                        trigger="click"
                        open={open}
                        onOpenChange={handleOpenChange}
                    >
                        <Avatar
                            className={`${styles.avatars} ${styles.avatarCustom} ${styles.clickableAvatar}`}
                        >
                            +{remainingCount}
                        </Avatar>
                    </Popover>
                )}
            </Box>
        </Box>
    );
};

export default AvatarGroupComponent;
