import React, { useEffect, useState, useRef } from 'react';
import { DateCalendarProps, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar, PickersDay, DayCalendarSkeleton, PickersDayProps } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';
import { ptBR } from '@mui/x-date-pickers/locales';
import estilos from './index.module.scss';
import CabecalhoCalendario from './CustomCalendarHeader';
import { styled } from '@mui/material';

interface Obrigacao {
	id: number;
	dataCriacao: string;
	dataVencimento: string;
	dataConclusao: string | null;
	concluida: boolean;
}

interface CustomDateCalendarProps {
	obrigacoes: Obrigacao[];
	onDateChange: (date: string | null) => void;
	loading: boolean;
	diaFiltroSelecionado: string;
}

const DiaServidor: React.FC<PickersDayProps<dayjs.Dayjs> & { obrigacoes: Obrigacao[]; selectedDay: string | null }> = (
	props,
) => {
	const { day, className, obrigacoes, selectedDay, ...other } = props;
	const dateISO = day.format('YYYY-MM-DD');
	const obrigacao = obrigacoes.find((ob: Obrigacao) => dayjs(ob.dataVencimento).format('YYYY-MM-DD') === dateISO);

	let classeDia = estilos.diaPadrao;

	if (dateISO === selectedDay) {
		classeDia += ` ${estilos.botaoDiaSelecionado}`;
	} else if (obrigacao) {
		if (obrigacao.concluida) {
			if (
				dayjs(obrigacao.dataConclusao).isBefore(dayjs(obrigacao.dataVencimento)) ||
				dayjs(obrigacao.dataConclusao).isSame(dayjs(obrigacao.dataVencimento))
			) {
				classeDia = estilos.diaConcluidaNoPrazo;
			} else {
				classeDia = estilos.diaConcluidaComAtraso;
			}
		} else {
			if (dayjs(obrigacao.dataVencimento).isBefore(dayjs(), 'day')) {
				classeDia = estilos.diaVencidaNaoConcluida;
			} else {
				classeDia = estilos.diaNaoConcluidaNoPrazo;
			}
		}
	}

	return (
		<PickersDay
			{...other}
			day={day}
			outsideCurrentMonth={props.outsideCurrentMonth}
			className={`${className} ${classeDia}`}
			onDaySelect={(day) => {
				if (other.onDaySelect) {
					other.onDaySelect(day);
				}
			}}
		/>
	);
};

const CustomDateCalendar: React.FC<CustomDateCalendarProps> = ({
	obrigacoes,
	onDateChange,
	loading,
	diaFiltroSelecionado,
}) => {
	const requestAbortController = useRef<AbortController | null>(null);
	const [, setIsLoading] = useState(false);
	const [selectedDate, setSelectedDate] = useState<dayjs.Dayjs | null>(null);

	const isDisabled = loading;

	const fetchHighlightedDays = () => {
		const controller = new AbortController();
		requestAbortController.current = controller;
		setIsLoading(false);
	};

	useEffect(() => {
		fetchHighlightedDays();
		return () => requestAbortController.current?.abort();
	}, [selectedDate]);

	const handleMonthChange = (newDate: dayjs.Dayjs) => {
		if (requestAbortController.current) {
			requestAbortController.current.abort();
		}

		setSelectedDate(newDate);
		setIsLoading(true);
		fetchHighlightedDays();
	};

	const handleDateChange = (date: dayjs.Dayjs | null) => {
		const formattedDate = date ? date.format('YYYY-MM-DD') : null;
		setSelectedDate(date);
		onDateChange(formattedDate);
	};

	const handleClearSelection = () => {
		setSelectedDate(null);
		onDateChange(null);
	};

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const StyledDateCalendar = styled(DateCalendar)<DateCalendarProps<dayjs.Dayjs>>(({ theme }) => ({
		width: '100%',
		maxHeight: '250px',

		'& .MuiDayCalendar-weekContainer': {
			justifyContent: 'space-around',
			marginBottom: '4px',
		},
	}));

	return (
		<div className={estilos.calendarioCustomizado}>
			<LocalizationProvider
				dateAdapter={AdapterDayjs}
				adapterLocale='pt-br'
				localeText={ptBR.components.MuiLocalizationProvider.defaultProps.localeText}
			>
				<StyledDateCalendar
					value={selectedDate}
					onChange={handleDateChange}
					disabled={isDisabled}
					onMonthChange={handleMonthChange}
					renderLoading={() => <DayCalendarSkeleton />}
					showDaysOutsideCurrentMonth
					dayOfWeekFormatter={(day) => {
						const dayNames = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'];
						const dayIndex = dayjs(day, 'dd').day();
						return dayNames[dayIndex];
					}}
					slots={{
						day: (props) => (
							<DiaServidor
								{...props}
								obrigacoes={obrigacoes}
								selectedDay={selectedDate?.format('YYYY-MM-DD') || null}
							/>
						),
						calendarHeader: (props) => (
							<CabecalhoCalendario
								{...props}
								currentMonth={selectedDate || dayjs()}
								onMonthChange={handleMonthChange}
								selectedDate={selectedDate}
								handleClearSelection={handleClearSelection}
								diaFiltroSelecionado={diaFiltroSelecionado}
							/>
						),
					}}
				/>
			</LocalizationProvider>
		</div>
	);
};

export default CustomDateCalendar;
