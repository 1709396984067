import { useState, useEffect, useCallback } from 'react';
import { TObrigacao } from '../ts/types/Obrigacoes';
import ObrigacaoService from '../services/Obrigacoes/Obrigacoes.service';
import { useGovernancaContext } from '../../../../context/GovernancaContext';

export function useObrigacoes() {
	const { empresaAtiva } = useGovernancaContext();
	const [obrigacoes, setObrigacoes] = useState<TObrigacao[]>([]);
	const obrigacaoService = new ObrigacaoService();

	const fetchObrigacoes = useCallback(async () => {
		try {
			if (empresaAtiva) {
				const response = await obrigacaoService.getAllObrigacoesEssenciais(empresaAtiva, {
					concluidas: false,
				});
				setObrigacoes(response.data.result);
			}
		} catch (error) {
			console.error('Erro ao buscar obrigações:', error);
		}
	}, [empresaAtiva]);

	useEffect(() => {
		fetchObrigacoes();
	}, [fetchObrigacoes]);

	const handleObrigacaoChange = (obrigacaoAtualizada: TObrigacao) => {
		setObrigacoes((prev) =>
			prev.map((obrigacao) => (obrigacao.id === obrigacaoAtualizada.id ? obrigacaoAtualizada : obrigacao)),
		);
	};

	return {
		obrigacoes,
		fetchObrigacoes,
		handleObrigacaoChange,
	};
}
