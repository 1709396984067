/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useEffect, useState } from 'react';
import { ResponsiveContainer } from 'recharts';
import { Col, Flex, Row, Typography, Button, Icons } from '@jcm/design-system';
import PieChartComp from './GraficoTorta';
import CustomDateCalendar from './Calendar/CustomDateCalendar/CustomDateCalendar';
import CartaoObrigacao from './CartaoObrigacao';
import BarraFerramentasObrigacoes from './BarraFerramentasObrigacoes';
import estilo from './index.module.scss';
import { TObrigacao } from '../../ts/types/Obrigacoes';
import ObrigacaoService from '../../services/Obrigacoes/Obrigacoes.service';
import TotalObrigacoesCard from './TotalObrigacoesCard/TotalObrigacoesCard';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import { useGovernancaContext } from '../../../../../context/GovernancaContext';
import { Pagination } from '@mui/material';
import { TApiResponse } from '../../services/helpers/ApiResponse';
import { TEstatisticasObrigacoes } from '../../ts/types/EstatisticasObrigacoes';
import EnumSituacaoAcaoObrigacao from '../../ts/enums/SituacaoAcaoObrigacaoEnum';
import Lottie from 'lottie-react';
import LoadingListaObrigacoes from '../../../../../assets/loadings/CarregandoListaObrigacoes.json';
import ObrigacoesTabMenu from './ObrigacoesTabMenu';
import { listarIntervaloAnoAtual } from '../../utils/listarIntervaloAnoAtras';
import usePermissao from '../../hooks';
import { Permissoes } from '../../ts/enums/PermissoesEnum';
import Filtros from './FiltrosObrigacoes/filtros';
import { useForm } from 'react-hook-form';

interface FiltrosObrigacoesFormValues {
	origem: string | number;
	responsavel: string | number;
	aprovador: string | number;
	situacao: string;
	tipo: string;
	descricao: string;
}
import { Layout, Tooltip } from 'antd';
import RangeDatePicker from '../../../../../shared/components/rangeDatePicker/rangeDatePicker';

const { Sider } = Layout;
export default function Obrigacoes() {
	const { empresaAtiva } = useGovernancaContext();
	const [activeTabKey, setActiveTabKey] = useState<string>('todasObrigacoes');
	const [loading, setLoading] = useState(true);
	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize] = useState(10);
	const [totalPages, setTotalPages] = useState(1);
	const [filtroSelecionado, setFiltroSelecionado] = useState<string>('todasObrigacoes');
	const [obrigacoesQuery, setObrigacoesQuery] = useState<TObrigacao[]>([]);
	const [todasObrigacoesQuery, setTodasObrigacoesQuery] = useState<TObrigacao[]>([]);
	const [tabMenuFiltro, setTabMenuFiltro] = useState<{ name: string; value: number }[]>([]);
	const [graficoData, setGraficoData] = useState<{ name: string; value: number }[]>([]);
	const [dadosCard, setDadosCard] = useState({ total: 0, legais: 0, proprias: 0 });
	const [simplifiedData, setSimplifiedData] = useState<
		{ id: number; dataCriacao: string; dataVencimento: string; dataConclusao: string | null; concluida: boolean }[]
	>([]);
	const [selectedDate, setSelectedDate] = useState<string | null>(null);
	const [showCompleted, setShowCompleted] = useState(true);
	const [rangeDateWorking, setRangeDateWorking] = useState(false);
	const [rangeDate, setRangeDate] = useState<{ startDate: string; endDate: string }>();
	const [collapsed, setCollapsed] = useState(false);
	const [funcionarios, setFuncionarios] = useState<any[]>([]);
	const [intervaloDataSelecionado, setIntervaloDataSelecionado] = useState<[Dayjs | null, Dayjs | null]>([
		dayjs().startOf('month'),
		dayjs().endOf('month'),
	]);
	const [showCalendario, setShowCalendario] = useState(true);
	const _obrigacoesService = new ObrigacaoService();
	const temPermissaoAdministrador = usePermissao([Permissoes.AdministradorEmpresa, Permissoes.AdministradorSistema]);
	const { control } = useForm<FiltrosObrigacoesFormValues>();
	const [activeContent, setActiveContent] = useState('Calendario');
	const [filtrosAtivos, setFiltrosAtivos] = useState(false);

	useEffect(() => {
		if (empresaAtiva) {
			_obrigacoesService.getAllUsuarios(empresaAtiva).then((response) => {
				setFuncionarios(response);
			});
		}
	}, [empresaAtiva]);

	const toggleCollapsed = () => setCollapsed(!collapsed);

	const toggleCalendario = () => {
		setActiveContent('Calendario');
		setShowCalendario(true);
		toggleCollapsed();
	};

	const toggleFiltro = () => {
		setActiveContent('Filtro');
		setShowCalendario(false);
		toggleCollapsed();
	};

	const abrirFiltros = () => {
		setActiveContent('Filtro');
		setShowCalendario(false);
		setCollapsed(false);
	};

	const aplicarFiltros = async (filtrosSelecionados: Record<string, string | number>) => {
		const algumFiltroAtivo = Object.values(filtrosSelecionados).some(
			(valor) => valor !== '' && valor !== null && valor !== undefined,
		);
		setFiltrosAtivos(algumFiltroAtivo);

		const datas = listarIntervaloAnoAtual();

		const formatarDataDayJsParaString = (data: string | Dayjs | undefined): string =>
			data ? (typeof data === 'string' ? data : data.format('YYYY/MM/DD')) : '';

		const parametrosDatas =
			!rangeDateWorking && !intervaloDataSelecionado[0] && !intervaloDataSelecionado[1]
				? datas
				: {
						dataInicio: formatarDataDayJsParaString(intervaloDataSelecionado?.[0] ?? ''),
						dataFim: formatarDataDayJsParaString(intervaloDataSelecionado?.[1] ?? ''),
					};

		const [obrigacoesResponse] = await fetchObrigacoesAndEstatisticas(parametrosDatas);

		const obrigacoesResultados = obrigacoesResponse.data.result;

		let filteredObrigacoes = obrigacoesResultados;

		if (filtrosSelecionados.origem) {
			filteredObrigacoes = filteredObrigacoes.filter(
				(obrigacao: TObrigacao) => obrigacao.origemId === filtrosSelecionados.origem,
			);
		}

		if (filtrosSelecionados.responsavel && filtrosSelecionados.responsavel !== '') {
			filteredObrigacoes = filteredObrigacoes.filter((obrigacao: TObrigacao) =>
				obrigacao.responsaveis.some(
					(responsavel) => responsavel.funcionario?.funcionarioId === Number(filtrosSelecionados.responsavel),
				),
			);
		}

		if (filtrosSelecionados.aprovador && filtrosSelecionados.aprovador !== '') {
			filteredObrigacoes = filteredObrigacoes.filter((obrigacao: TObrigacao) =>
				obrigacao.aprovadores.some(
					(aprovador) => aprovador.funcionario?.funcionarioId === Number(filtrosSelecionados.aprovador),
				),
			);
		}

		if (filtrosSelecionados.situacao) {
			if (filtrosSelecionados.situacao === 'concluida') {
				filteredObrigacoes = filteredObrigacoes.filter(
					(obrigacao: TObrigacao) =>
						obrigacao.situacao === EnumSituacaoAcaoObrigacao.ConcluidaNoPrazo ||
						obrigacao.situacao === EnumSituacaoAcaoObrigacao.ConcluidaComAtraso,
				);
			} else if (filtrosSelecionados.situacao === 'emAndamento') {
				filteredObrigacoes = filteredObrigacoes.filter(
					(obrigacao: TObrigacao) => obrigacao.situacao === EnumSituacaoAcaoObrigacao.NoPrazo,
				);
			}
		}

		if (filtrosSelecionados.tipo) {
			filteredObrigacoes = filteredObrigacoes.filter((obrigacao: TObrigacao) =>
				filtrosSelecionados.tipo === 'legal' ? obrigacao.ehLegal : !obrigacao.ehLegal,
			);
		}

		if (typeof filtrosSelecionados.nome === 'string' && filtrosSelecionados.nome.trim() !== '') {
			const nomeFiltro = filtrosSelecionados.nome.toLowerCase();
			filteredObrigacoes = filteredObrigacoes.filter((obrigacao: TObrigacao) =>
				obrigacao.nome.toLowerCase().includes(nomeFiltro),
			);
		}

		setObrigacoesQuery(filteredObrigacoes);
		setTodasObrigacoesQuery(filteredObrigacoes);
		updatePagination(filteredObrigacoes);
		setSimplifiedData(extrairDadosSimplificados(filteredObrigacoes));
		handleFiltragensListagem(filteredObrigacoes);
		setGraficoData(processarDadosParaGraficoPreSelecionado(filteredObrigacoes));
		setDadosCard(capturarDadosParaCard(filteredObrigacoes));
	};

	useEffect(() => {
		if (temPermissaoAdministrador) {
			setShowCompleted(false);
		}
	}, [temPermissaoAdministrador]);

	useEffect(() => {
		initializeComponent();
	}, [empresaAtiva, showCompleted]);

	useEffect(() => {
		handleFiltragensListagem(todasObrigacoesQuery);
	}, [currentPage, selectedDate, filtroSelecionado, rangeDateWorking]);

	const initializeComponent = () => {
		setLoading(true);

		if (empresaAtiva !== undefined) {
			fetchObrigacoesData();

			const datas = listarIntervaloAnoAtual();
			setIntervaloDataSelecionado([dayjs(datas.dataInicio), dayjs(datas.dataFim)]);
		}
	};

	const fetchObrigacoesData = async () => {
		setLoading(true);

		try {
			const datas = listarIntervaloAnoAtual();
			const [obrigacoesResponse, estatisticasResponse] = await fetchObrigacoesAndEstatisticas(datas);

			const obrigacoesResultados = obrigacoesResponse.data.result;
			const estatisticasResultados = estatisticasResponse.data.result;

			setTodasObrigacoesQuery(obrigacoesResultados);
			handleFiltragensListagem(obrigacoesResultados);
			setSimplifiedData(extrairDadosSimplificados(obrigacoesResultados));

			let resultadosFiltrados = obrigacoesResultados;
			let estatisticasFiltradas = estatisticasResultados;

			if (selectedDate) {
				const formattedSelectedDate = new Date(selectedDate).toISOString().split('T')[0];

				resultadosFiltrados = obrigacoesResultados.filter(
					(item) => new Date(item.dataVencimento).toISOString().split('T')[0] === formattedSelectedDate,
				);

				estatisticasFiltradas = calcularEstatisticas(resultadosFiltrados);
			}

			setGraficoData(processarDadosParaGrafico(estatisticasFiltradas));
			setTabMenuFiltro(calcularTabMenuFiltro(resultadosFiltrados));
			setDadosCard(capturarDadosParaCard(resultadosFiltrados));
		} catch (error) {
			console.error('Erro ao buscar obrigações:', error);
		} finally {
			setLoading(false);
		}
	};

	const calcularEstatisticas = (dados: TObrigacao[]) => ({
		total: dados.length,
		atrasadas: dados.filter((item) => item.situacao === EnumSituacaoAcaoObrigacao.Atrasada).length,
		concluidasComAtraso: dados.filter((item) => item.situacao === EnumSituacaoAcaoObrigacao.ConcluidaComAtraso).length,
		noPrazo: dados.filter((item) => item.situacao === EnumSituacaoAcaoObrigacao.NoPrazo).length,
		concluidasNoPrazo: dados.filter((item) => item.situacao === EnumSituacaoAcaoObrigacao).length,
	});

	const fetchObrigacoesAndEstatisticas = (datas: {
		dataInicio: string;
		dataFim: string;
	}): Promise<[TApiResponse<TObrigacao[]>, TApiResponse<TEstatisticasObrigacoes>]> => {
		if (showCompleted) {
			return Promise.all([
				_obrigacoesService.getMinhasObrigacoes(empresaAtiva as string | number, {
					dataInicio: datas.dataInicio,
					dataFim: datas.dataFim,
				}),
				_obrigacoesService.getMinhasEstatisticasObrigacoes(empresaAtiva as string | number, {
					dataInicio: datas.dataInicio,
					dataFim: datas.dataFim,
				}),
			]);
		}
		return Promise.all([
			_obrigacoesService.getAllObrigacoesEssenciais(empresaAtiva as string | number, {
				dataInicio: datas.dataInicio,
				dataFim: datas.dataFim,
				concluidas: true,
			}),
			_obrigacoesService.getAllEstatisticaObrigacoesEmpresa(empresaAtiva as string | number, {
				dataInicio: datas.dataInicio,
				dataFim: datas.dataFim,
			}),
		]);
	};

	const updatePagination = (obrigacoes: TObrigacao[]) => {
		const totalItems = obrigacoes.length;
		setTotalPages(Math.ceil(totalItems / pageSize));
		const paginatedData = obrigacoes.slice((currentPage - 1) * pageSize, currentPage * pageSize);
		setObrigacoesQuery(paginatedData);
	};

	const processarDadosParaGrafico = (estatisticas: TEstatisticasObrigacoes) => [
		{ name: 'Concluídas no prazo', value: estatisticas.concluidasNoPrazo || 0 },
		{ name: 'Concluídas com atraso', value: estatisticas.concluidasComAtraso || 0 },
		{ name: 'Vencidas e não concluídas', value: estatisticas.atrasadas || 0 },
		{ name: 'Não concluídas e no Prazo', value: estatisticas.noPrazo || 0 },
	];

	const calcularTabMenuFiltro = (obrigacoes: TObrigacao[]) => {
		const aguardandoAprovacao = obrigacoes.filter((obrigacao) => {
			if (obrigacao.aprovada) {
				return false;
			}

			const hasCancelamentoAprovado = obrigacao.pedidoCancelarObrigacao.some(
				(pedido) => pedido.estadoStatusAprovada === 1,
			);
			const hasAlteracaoAprovada = obrigacao?.pedidoAlteracaoDataFinal.some(
				(pedido) => pedido.estadoStatusAprovada === 1,
			);
			const hasObrigacaoAprovada = obrigacao.estadoStatusAprovada === 1;

			return hasCancelamentoAprovado || hasAlteracaoAprovada || hasObrigacaoAprovada;
		});
		const aprovada = obrigacoes.filter((item) => item.aprovada).length;

		return [
			{ name: 'Aguardando Aprovação', value: aguardandoAprovacao.length },
			{ name: 'Todas Obrigações', value: obrigacoes.length },
			{ name: 'Aprovadas', value: aprovada },
		];
	};

	const capturarDadosParaCard = (obrigacoes: TObrigacao[]) => {
		let total = 0,
			legais = 0,
			proprias = 0;
		obrigacoes.forEach((obrigacao) => {
			total++;
			if (obrigacao.ehLegal) legais++;
			else proprias++;
		});
		return { total, legais, proprias };
	};

	const extrairDadosSimplificados = (obrigacoes: TObrigacao[]) =>
		obrigacoes.map((obrigacao) => ({
			id: obrigacao.id,
			dataCriacao: obrigacao.dataCriacao,
			dataVencimento: obrigacao.dataVencimento,
			dataConclusao: obrigacao.dataConclusao,
			concluida: obrigacao.concluida,
		}));

	const handleFiltragensListagem = (obrigacoes: TObrigacao[]) => {
		let filteredObrigacoes = obrigacoes;

		if (selectedDate) {
			setRangeDate(undefined);
			setRangeDateWorking(false);
		}

		switch (filtroSelecionado) {
			case 'todasObrigacoes': {
				if (selectedDate && !rangeDateWorking) {
					filteredObrigacoes = obrigacoes.filter((obrigacao) => formatDate(obrigacao.dataVencimento) === selectedDate);
				}

				if (rangeDateWorking && rangeDate) {
					filteredObrigacoes = obrigacoes.filter((obrigacao) => {
						const dataVencimento = dayjs(obrigacao.dataVencimento).format('YYYY-MM-DD');
						return dataVencimento >= rangeDate?.startDate && dataVencimento <= rangeDate?.endDate;
					});
				}

				updatePagination(filteredObrigacoes);
				setGraficoData(processarDadosParaGraficoPreSelecionado(filteredObrigacoes));
				setDadosCard(capturarDadosParaCard(filteredObrigacoes));
				break;
			}

			case 'obrigacoesVencidas': {
				const now = new Date();
				const startOfToday = new Date(now.getFullYear(), now.getMonth(), now.getDate());

				filteredObrigacoes = obrigacoes.filter((obrigacao) => {
					const dataVencimento = new Date(obrigacao.dataVencimento);

					return (
						dataVencimento < startOfToday &&
						!obrigacao.concluida &&
						(!selectedDate || formatDate(obrigacao.dataVencimento) === selectedDate)
					);
				});

				if (rangeDateWorking && rangeDate) {
					filteredObrigacoes = obrigacoes.filter((obrigacao) => {
						const dataVencimento = dayjs(obrigacao.dataVencimento).format('YYYY-MM-DD');
						const dataVencimentoAgora = new Date(obrigacao.dataVencimento);

						return (
							dataVencimentoAgora < startOfToday &&
							!obrigacao.concluida &&
							dataVencimento >= rangeDate?.startDate &&
							dataVencimento <= rangeDate?.endDate
						);
					});
				}

				updatePagination(filteredObrigacoes);
				break;
			}

			case 'obrigacoesAguardandoAprovacoes': {
				const obrigacoesAguardandoEstado = selectedDate ? obrigacoesQuery : obrigacoes;

				filteredObrigacoes = obrigacoesAguardandoEstado.filter((obrigacao) => {
					if (obrigacao.aprovada) {
						return false;
					}
					const hasCancelamentoAprovado = obrigacao.pedidoCancelarObrigacao.some(
						(pedido) => pedido.estadoStatusAprovada === 1,
					);
					const hasAlteracaoAprovada = obrigacao?.pedidoAlteracaoDataFinal.some(
						(pedido) => pedido.estadoStatusAprovada === 1,
					);
					const hasObrigacaoAprovada = obrigacao.estadoStatusAprovada === 1;

					return (
						hasCancelamentoAprovado ||
						hasAlteracaoAprovada ||
						(hasObrigacaoAprovada && (!selectedDate || formatDate(obrigacao.dataVencimento) === selectedDate))
					);
				});

				updatePagination(filteredObrigacoes);
				break;
			}

			case 'obrigacoesConcluidas': {
				let obrigacoesConcluidas: Array<TObrigacao> = todasObrigacoesQuery;

				if (selectedDate && !rangeDateWorking) {
					obrigacoesConcluidas = obrigacoes.filter(
						(obrigacao) => formatDate(obrigacao.dataVencimento) === selectedDate,
					);
				}

				if (rangeDateWorking && rangeDate) {
					obrigacoesConcluidas = obrigacoes.filter((obrigacao) => {
						const dataVencimento = dayjs(obrigacao.dataVencimento).format('YYYY-MM-DD');
						return dataVencimento >= rangeDate?.startDate && dataVencimento <= rangeDate?.endDate;
					});
				}

				filteredObrigacoes = obrigacoesConcluidas.filter((obrigacao) => {
					const hasConclusaoAprovada = obrigacao.concluida == true;
					const hasDataConclusao = obrigacao?.dataConclusao != null;
					const hasObrigacaoAprovada = obrigacao.estadoStatusAprovada === 2;

					return hasConclusaoAprovada && hasDataConclusao && hasObrigacaoAprovada;
				});

				updatePagination(filteredObrigacoes);
				break;
			}

			case 'obrigacoesNoPrazo': {
				const now = new Date();
				const startOfToday = new Date(now.getFullYear(), now.getMonth(), now.getDate());

				filteredObrigacoes = obrigacoes.filter((obrigacao) => {
					const dataVencimento = new Date(obrigacao.dataVencimento);

					return (
						dataVencimento >= startOfToday &&
						!obrigacao.concluida &&
						(!selectedDate || formatDate(obrigacao.dataVencimento) === selectedDate)
					);
				});

				if (rangeDateWorking && rangeDate) {
					filteredObrigacoes = obrigacoes.filter((obrigacao) => {
						const dataVencimento = dayjs(obrigacao.dataVencimento).format('YYYY-MM-DD');
						const dataVencimentoAgora = new Date(obrigacao.dataVencimento);

						return (
							dataVencimentoAgora >= startOfToday &&
							!obrigacao.concluida &&
							dataVencimento >= rangeDate?.startDate &&
							dataVencimento <= rangeDate?.endDate
						);
					});
				}

				updatePagination(filteredObrigacoes);
				break;
			}

			default:
				console.warn(`Filtro selecionado não reconhecido: ${filtroSelecionado}`);
				break;
		}
	};

	const handleDateChange = (date: string | null) => {
		setActiveTabKey('todasObrigacoes');
		setFiltroSelecionado('todasObrigacoes');
		setSelectedDate(date);
		setCurrentPage(1);
	};

	const formatDate = (dateString: string) => dayjs(dateString).format('YYYY-MM-DD');

	const handlePageChange = (_: unknown, page: number) => setCurrentPage(page);

	const handleTabChange = (tabKey: string) => {
		setActiveTabKey(tabKey);
		setFiltroSelecionado(tabKey);
		setCurrentPage(1);
	};

	const processarDadosParaGraficoPreSelecionado = (obrigacoes: TObrigacao[]) => {
		let concluidasNoPrazo = 0;
		let concluidasComAtraso = 0;
		let vencidasNaoConcluidas = 0;
		let naoConcluidasNoPrazo = 0;
		let aguardandoAprovadores = 0;
		let aprovadas = 0;

		const aguardandoAprovacao = obrigacoes.filter((obrigacao) => {
			if (obrigacao.aprovada) {
				return false;
			}
			const hasCancelamentoAprovado =
				obrigacao.pedidoCancelarObrigacao?.some((pedido) => pedido.estadoStatusAprovada === 1) || false;

			const hasAlteracaoAprovada =
				obrigacao.pedidoAlteracaoDataFinal?.some((pedido) => pedido.estadoStatusAprovada === 1) || false;

			const hasObrigacaoAprovada = obrigacao.estadoStatusAprovada === 1;

			return hasCancelamentoAprovado || hasAlteracaoAprovada || hasObrigacaoAprovada;
		});
		aprovadas = obrigacoes.filter((item) => item.aprovada).length;
		aguardandoAprovadores = aguardandoAprovacao.length;
		setTabMenuFiltro([
			{ name: 'Aguardando Aprovação', value: aguardandoAprovadores },
			{ name: 'Todas Obrigações', value: obrigacoes.length },
			{ name: 'Aprovadas', value: aprovadas },
		]);

		obrigacoes.forEach((obrigacao) => {
			const { situacao } = obrigacao;

			switch (situacao) {
				case EnumSituacaoAcaoObrigacao.ConcluidaNoPrazo:
					concluidasNoPrazo++;
					break;
				case EnumSituacaoAcaoObrigacao.ConcluidaComAtraso:
					concluidasComAtraso++;
					break;
				case EnumSituacaoAcaoObrigacao.Atrasada:
					vencidasNaoConcluidas++;
					break;
				case EnumSituacaoAcaoObrigacao.NoPrazo:
					naoConcluidasNoPrazo++;
					break;
				default:
					break;
			}
		});

		return [
			{ name: 'Concluídas no prazo', value: concluidasNoPrazo },
			{ name: 'Concluídas com atraso', value: concluidasComAtraso },
			{ name: 'Vencidas e não concluídas', value: vencidasNaoConcluidas },
			{ name: 'Não concluídas e no Prazo', value: naoConcluidasNoPrazo },
			{ name: 'Aprovadas', value: aprovadas },
		];
	};

	const handleDateRangeChange = async (dates: [Dayjs | null, Dayjs | null] | null) => {
		if (!dates) {
			const datas = listarIntervaloAnoAtual();
			const defaultRange: [Dayjs, Dayjs] = [dayjs(datas.dataInicio), dayjs(datas.dataFim)];
			setIntervaloDataSelecionado(defaultRange);

			fetchObrigacoesData();
			setRangeDateWorking(true);

			return;
		}

		const [startDate, endDate] = dates;
		if (!startDate || !endDate) {
			setRangeDateWorking(false);
			return;
		}

		setLoading(true);
		setActiveTabKey('todasObrigacoes');
		setFiltroSelecionado('todasObrigacoes');
		setCurrentPage(1);
		setSelectedDate(null);
		setRangeDateWorking(true);
		setIntervaloDataSelecionado(dates);
		setRangeDate({
			startDate: startDate.format('YYYY-MM-DD'),
			endDate: endDate.format('YYYY-MM-DD'),
		});

		try {
			const [obrigacoesResponse] = await fetchObrigacoesAndEstatisticas({
				dataInicio: startDate.format('YYYY-MM-DD'),
				dataFim: endDate.format('YYYY-MM-DD'),
			});

			const result = obrigacoesResponse.data.result;
			setTodasObrigacoesQuery(result);
			updatePagination(result);
			setTabMenuFiltro(calcularTabMenuFiltro(result));
			setDadosCard(capturarDadosParaCard(result));
			setSimplifiedData(extrairDadosSimplificados(result));
		} catch {
			return;
		} finally {
			setRangeDateWorking(false);
			setLoading(false);
		}
	};

	const toggleContent = (content: string) => {
		if (content === activeContent && !collapsed) {
			toggleCollapsed();
		} else {
			setShowCalendario(content === 'Calendario');
			setActiveContent(content);
			setCollapsed(false);
		}
	};

	const handleSwitchChange = (checked: boolean) => {
		setShowCompleted(checked);
		setLoading(true);
	};

	const atualizarDados = () => {
		fetchObrigacoesData();
	};

	const handleObrigacaoChange = (obrigacaoAtualizada: TObrigacao) => {
		setTodasObrigacoesQuery((prevObrigacoes) => {
			const index = prevObrigacoes.findIndex((obrigacao) => obrigacao.id === obrigacaoAtualizada.id);
			if (index !== -1) {
				const novasObrigacoes = [...prevObrigacoes];
				novasObrigacoes[index] = obrigacaoAtualizada;
				return novasObrigacoes;
			} else {
				atualizarDados();
				return prevObrigacoes;
			}
		});

		setObrigacoesQuery((prevObrigacoes) => {
			const index = prevObrigacoes.findIndex((obrigacao) => obrigacao.id === obrigacaoAtualizada.id);
			if (index !== -1) {
				const novasObrigacoes = [...prevObrigacoes];
				novasObrigacoes[index] = obrigacaoAtualizada;
				return novasObrigacoes;
			} else {
				atualizarDados();
				return prevObrigacoes;
			}
		});
	};

	return (
		<Layout className={estilo.containerObrigacoes}>
			<Row gutter={[40, 40]} className={estilo.obrigacoesPagina}>
				<Col
					className={estilo.obrigacaoCol}
					style={{ maxWidth: collapsed ? 'calc(100% - 80px)' : 'calc(100% - 300px)' }}
				>
					<Flex vertical gap={13}>
						<BarraFerramentasObrigacoes
							loading={loading}
							empresaId={empresaAtiva || ''}
							onSwitchChange={handleSwitchChange}
							onObrigacaoChange={handleObrigacaoChange}
							showCompleted={showCompleted}
						/>
						<ObrigacoesTabMenu
							aguardandoAprovacao={tabMenuFiltro.find((d) => d.name === 'Aguardando Aprovação')?.value || 0}
							concluidasNoPrazo={graficoData.find((d) => d.name === 'Concluídas no prazo')?.value || 0}
							concluidasComAtraso={graficoData.find((d) => d.name === 'Concluídas com atraso')?.value || 0}
							vencidasNaoConcluidas={graficoData.find((d) => d.name === 'Vencidas e não concluídas')?.value || 0}
							naoConcluidasNoPrazo={graficoData.find((d) => d.name === 'Não concluídas e no Prazo')?.value || 0}
							todasObrigacoes={tabMenuFiltro.find((d) => d.name === 'Todas Obrigações')?.value || 0}
							aprovadas={tabMenuFiltro.find((d) => d.name === 'Aprovadas')?.value || 0}
							onTabChange={handleTabChange}
							onSwitchChange={handleSwitchChange}
							activeTabKey={activeTabKey}
							showCompleted={showCompleted}
							loading={loading}
						/>
					</Flex>
					{filtrosAtivos && (
						<Button className={estilo.btFiltrosAplicados} type='outlined' onClick={abrirFiltros}>
							Filtros aplicados
						</Button>
					)}

					{loading || (todasObrigacoesQuery.length === 0 && !rangeDateWorking && !rangeDate && loading) ? (
						<Flex justify='center'>
							<Lottie loop animationData={LoadingListaObrigacoes} className={estilo.tamanhoLoading} />
						</Flex>
					) : obrigacoesQuery.length > 0 ? (
						<>
							{obrigacoesQuery.map((obrigacao) => (
								<CartaoObrigacao
									onAtualizar={atualizarDados}
									obrigacao={obrigacao}
									key={obrigacao.id}
									empresaId={empresaAtiva || ''}
									onObrigacaoChange={handleObrigacaoChange}
								/>
							))}
							<Flex justify='center'>
								<Pagination count={totalPages} page={currentPage} onChange={handlePageChange} />
							</Flex>
						</>
					) : (
						<div className={estilo.avisoSemObrigacao}>
							<Flex justify='center'>
								<Typography.Headline>Não há Obrigações a serem listadas.</Typography.Headline>
							</Flex>
						</div>
					)}
				</Col>

				<Col>
					<Sider
						width={300}
						collapsible
						collapsed={collapsed}
						collapsedWidth={0}
						trigger={null}
						className={estilo.obrigacoesSider}
					>
						<Col className={estilo.botoesContainer}>
							<Flex className={estilo.espacamentoContainer}>
								<Tooltip placement='top' title={'Calendário e gráfico'}>
									<Button className={estilo.btnCalendario} onClick={() => toggleContent('Calendario')}>
										<Flex>
											<Icons.CalendarMonth className={estilo.fontSizeLarge} />
										</Flex>
									</Button>
								</Tooltip>
								<Tooltip placement='top' title={'Filtros'}>
									<Button className={estilo.btnFiltro} onClick={() => toggleContent('Filtro')}>
										<Flex>
											<Icons.FilterAlt className={estilo.fontSizeLarge} />
										</Flex>
									</Button>
								</Tooltip>
								<Tooltip placement='top' title={'Ocultar menu'}>
									<Button className={estilo.btnFecharSider} onClick={toggleCollapsed}>
										<Flex>
											<Icons.KeyboardArrowRight className={estilo.fontSizeLarge} />
										</Flex>
									</Button>
								</Tooltip>
							</Flex>
						</Col>
						{showCalendario ? (
							<Col id='graficoContainer' className={estilo.conteudoGraficoContainer}>
								<Flex vertical>
									<Flex vertical className={estilo.campoPeriodo}>
										<Flex className={estilo.estiloData}>
											<Typography.Label>Período </Typography.Label>
											<Tooltip
												title='Atenção! Filtrar por um intervalo de vários anos pode causar lentidão no sistema'
												mouseLeaveDelay={0}
											>
												<Icons.Info className={estilo.iconeAviso} />
											</Tooltip>
										</Flex>
										<RangeDatePicker
											onDateRangeChange={handleDateRangeChange}
											intervaloDataSelecionado={intervaloDataSelecionado}
										/>
									</Flex>
									<Row>
										<LocalizationProvider dateAdapter={AdapterDayjs}>
											<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
												<CustomDateCalendar
													loading={loading}
													obrigacoes={simplifiedData}
													onDateChange={handleDateChange}
													diaFiltroSelecionado={selectedDate || ''}
												/>
											</Col>
										</LocalizationProvider>
									</Row>
									<Row>
										<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} className='pieChart-div'>
											<ResponsiveContainer width='100%' height='100%'>
												<PieChartComp data={graficoData} />
											</ResponsiveContainer>
										</Col>
									</Row>
									<Row>
										<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
											<TotalObrigacoesCard
												total={dadosCard.total}
												legais={dadosCard.legais}
												proprias={dadosCard.proprias}
											/>
										</Col>
									</Row>
								</Flex>
							</Col>
						) : (
							<Col id='filtroContainer' className={estilo.conteudoGraficoContainer}>
								<Filtros
									aoAplicarFiltros={aplicarFiltros}
									listaResponsaveis={funcionarios}
									listaAprovadores={funcionarios}
									control={control}
									loading={loading}
								/>
							</Col>
						)}
					</Sider>

					<Flex vertical className={estilo.menuFlutuante}>
						<Tooltip placement='left' title={'Exibir menu de filtros'}>
							<Button className={estilo.btnOpenFiltro} onClick={toggleFiltro}>
								<Flex className={estilo.btFlutuante}>
									<Typography.Body size='small'>Filtros</Typography.Body>
									<Icons.FilterAlt />
								</Flex>
							</Button>
						</Tooltip>
						<Tooltip placement='left' title={'Exibir menu de calendário e gráfico'}>
							<Button className={estilo.btnOpenCalendario} onClick={toggleCalendario}>
								<Flex className={estilo.btFlutuante}>
									<Typography.Body size='small'>Calendário e Gráfico</Typography.Body>
									<Icons.CalendarMonth />
								</Flex>
							</Button>
						</Tooltip>
					</Flex>
				</Col>
			</Row>
		</Layout>
	);
}
