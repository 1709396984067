/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import styles from './ModalAlterarData.module.scss';
import ModalContainer from '../../../../../components/modalContainer/ModalContainer';
import { Button, Checkbox, DatePicker, Flex, Typography } from '@jcm/design-system';
import { Control, Controller, UseFormGetValues, UseFormSetValue, UseFormUnregister, useWatch } from 'react-hook-form';
import dayjs from 'dayjs';
import { Input } from 'antd';

const { TextArea } = Input;
interface ModalAlterarDataProps {
	isVisible: boolean;
	onClose: () => void;
	setValue: UseFormSetValue<any>;
	getValues: UseFormGetValues<any>;
	control: Control<any>;
	unregister: UseFormUnregister<any>;
	usuarioAprovador: boolean;
	usuarioResponsavel: boolean;
	handleSubmit: (data: any) => void;
}

const ModalAlterarData: React.FC<ModalAlterarDataProps> = ({
	isVisible,
	onClose,
	control,
	getValues,
	setValue,
	unregister,
	usuarioAprovador,
	usuarioResponsavel,
	handleSubmit,
}) => {
	const [isDateChanged, setIsDateChanged] = React.useState(false);
	const justificativaNaoAprovacaoDataAlterada = useWatch({
		control,
		name: 'justificativaNaoAprovacaoDataAlterada',
	});
	const justificativaDataAlterada = useWatch({
		control,
		name: 'justificativaDataAlterada',
	});
	const aprovarAlteracaoDataFinal = useWatch({
		control,
		name: 'aprovarAlteracaoDataFinal',
	});

	return (
		<ModalContainer
			title='Alterar data de vencimento'
			isVisible={isVisible}
			onClose={onClose}
			className={styles.modalAlterarData}
		>
			<Flex vertical gap={2}>
				<Flex className='campoObrigatorio'>
					<Typography.Body className='campoObrigatorio'>Nova data de vencimento</Typography.Body>
				</Flex>
				<Controller
					name='novaDataFinal'
					control={control}
					render={({ field }) => (
						<div style={{ width: 'fit-content' }}>
							<DatePicker
								allowClear
								placeholder='DD/MM/YYYY'
								format="DD/MM/YYYY"
								variant='filled'
								className={styles.alterarData}
								disabled={!usuarioResponsavel}
								defaultValue={getValues('novaDataFinal') ? dayjs(getValues('novaDataFinal')) : null}
								onChange={(e) => {
									const newDate = e ? dayjs(e).format('YYYY-MM-DD') : null;
									field.onChange(newDate);
									setValue('novaDataFinal', newDate);
									setIsDateChanged(Boolean(newDate));
								}}
								onFocus={(e) => e.target.blur()}
								name='novaDataFinal'
							/>
						</div>
					)}
				/>
			</Flex>

			<Controller
				name='justificativaDataAlterada'
				control={control}
				render={({ field }) => (
					<Flex vertical gap={2}>
						<Flex className='campoObrigatorio'>
							<Typography.Body>Justificativa para alteração da data final</Typography.Body>
						</Flex>

						<TextArea
							{...field}
							disabled={!usuarioResponsavel}
							placeholder='Justificativa'
							allowClear
							variant='filled'
						/>
					</Flex>
				)}
			/>

			{usuarioAprovador && !(usuarioAprovador && usuarioResponsavel) && (
				<>
					<Controller
						name='aprovarAlteracaoDataFinal'
						control={control}
						render={({ field }) => (
							<Flex gap={16}>
								<Flex gap={4}>
									<Checkbox
										type='checkbox'
										checked={field.value === 1}
										onChange={() => {
											setValue('aprovarAlteracaoDataFinal', field.value === 1 ? null : 1);
											setValue('estadoStatusAlterarData', 1);
										}} // 1 Status Aprovada
									/>
									<Typography.Body>Aprovar</Typography.Body>
								</Flex>
								<Flex gap={4}>
									<Checkbox
										type='checkbox'
										checked={field.value === 2}
										onChange={() => {
											setValue('aprovarAlteracaoDataFinal', field.value === 2 ? null : 2);
											setValue('estadoStatusAlterarData', 2);
										}} // 2 Status Reprovada
									/>
									<Typography.Body>Reprovar</Typography.Body>
								</Flex>
							</Flex>
						)}
					/>

					{getValues('aprovarAlteracaoDataFinal') === 2 && (
						<Controller
							name='justificativaNaoAprovacaoDataAlterada'
							control={control}
							render={({ field }) => (
								<Flex vertical gap={2}>
									<Flex className='campoObrigatorio'>
										<Typography.Body>
											Justificativa de Reprovação
										</Typography.Body>
									</Flex>
									<TextArea {...field} placeholder='Justificativa de Reprovação' allowClear variant='filled' />
								</Flex>
							)}
						/>
					)}
				</>
			)}

			<Flex style={{ gap: '1rem', justifySelf: 'center' }}>
				<Button
					type='filled-tonal'
					variant='error'
					onClick={() => {
						unregister('novaDataFinal');
						unregister('justificativaDataAlterada');
						unregister('solicitaAlterarDataFinal');
						onClose();
					}}
				>
					Cancelar
				</Button>

				<Button
					type='filled-tonal'
					variant='default'
					disabled={
						(usuarioAprovador &&
							aprovarAlteracaoDataFinal !== 1 &&
							!usuarioResponsavel &&
							(!justificativaNaoAprovacaoDataAlterada?.trim())) ||
						((!justificativaDataAlterada?.trim() || !isDateChanged) && usuarioResponsavel)
					}
					onClick={() => {
						setValue('solicitaAlterarDataFinal', true);
						onClose();
						handleSubmit(getValues());
					}}
				>
					{usuarioResponsavel && usuarioAprovador
						? 'Alterar data de vencimento e salvar'
						: usuarioResponsavel
							? 'Solicitar alteração e salvar'
							: usuarioAprovador
								? 'Responder solicitação e salvar'
								: 'Solicitar alteração e salvar'}
				</Button>
			</Flex>
		</ModalContainer>
	);
};

export default ModalAlterarData;
