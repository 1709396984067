/* eslint-disable @typescript-eslint/no-explicit-any */
import Truncate from '../../../../../shared/components/truncatedTextTooltip/TruncatedTextTooltip';
import { useGovernancaContext } from '../../../../../context/GovernancaContext';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import ObrigacaoService from '../../services/Obrigacoes/Obrigacoes.service';
import { Table, Select, Tag, TableColumnType, Tooltip } from 'antd';
import { Row, Col, Button, Typography } from '@jcm/design-system';
import { TApiResponse } from '../../services/helpers/ApiResponse';
import React, { useEffect, useState, useRef } from 'react';
import {
	TJustificativasAprovacao,
	TObrigacao,
	TPedidoAlteracaoDataFinal,
	TPedidoCancelarObrigacao,
} from '../../ts/types/Obrigacoes';
import { ITableProps, Flex, Icons, Surface } from '@jcm/design-system';
import { SortOrder } from 'antd/es/table/interface';
import isBetween from 'dayjs/plugin/isBetween';
import * as XLSX from 'xlsx';
import moment from 'moment';
import dayjs from 'dayjs';
import {
	createTextFilter,
	createTextOnFilter,
	createDateFilter,
	createDateOnFilter,
	createFilterList,
} from './filtros';
import './filtro.css';
import { EnumTipoAntencedenciaEmail, EnumTipoEmailFrequenciaEnvio } from '../../ts/enums/RegrasEnvioEmailEnum';
import EnumSituacaoAcaoObrigacao from '../../ts/enums/SituacaoAcaoObrigacaoEnum';
import estilos from './index.module.scss';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons';

dayjs.extend(isBetween);

const RelatorioObrigacoes: React.FC = () => {
	const { empresaAtiva } = useGovernancaContext();
	const [obrigacoes, setObrigacoes] = useState<TObrigacao[]>([]);
	const [tags, setTags] = useState<string[]>([]);
	const [isSelectOpen, setIsSelectOpen] = useState(false);
	const inputRef = useRef<any>(null);
	const [carregando, setCarregando] = useState<boolean>(true);
	const columnNames: Record<string, string> = {
		nome: 'Nome',
		codigo: 'Código',
		dataCriacao: 'Data de criação',
		dataVencimento: 'Data de vencimento',
		ativa: 'Ativa',
		ehLegal: 'Tipo',
		responsaveis: 'Responsáveis',
		aprovadores: 'Aprovadores',
		situacao: 'Situação',
		dataConclusao: 'Data da conclusão',
		acompanhamento: 'Acompanhamento',
		prazoLegal: 'Prazo legal',
		dataFinal: 'Data final',
		observacao: 'Observação',
		temporalidade: 'Temporalidade',
		fundamentacaoLegal: 'Fundamentação legal',
		formaCumprimento: 'Forma de cumprimento',
		frequenciaEnvio: 'Frequência de envio',
		antecedenciaEnvio: 'Antecedência de envio',
		frequenciaAposVencimento: 'Frequência pós-vencimento',
		outrosDestinatarios: 'Outros destinatários',
		destinatariosAtraso: 'Destinatários atraso',
		pedidoAlteracaoDataFinal: 'Justificativa vencimento',
		pedidoCancelarObrigacao: 'Justificativa cancelamento',
		justificativasAprovacao: 'Justificativa aprovação',
	};

	const [columnVisibility, setColumnVisibility] = useState<Record<string, boolean>>({
		nome: true,
		codigo: false,
		dataCriacao: false,
		dataVencimento: true,
		ativa: false,
		ehLegal: false,
		responsaveis: true,
		aprovadores: false,
		situacao: true,
		dataConclusao: false,
		acompanhamento: false,
		prazoLegal: false,
		dataFinal: false,
		observacao: true,
		temporalidade: true,
		fundamentacaoLegal: true,
		formaCumprimento: true,
		frequenciaEnvio: false,
		antecedenciaEnvio: false,
		frequenciaAposVencimento: false,
		outrosDestinatarios: false,
		destinatariosAtraso: false,
		pedidoAlteracaoDataFinal: false,
		pedidoCancelarObrigacao: false,
		justificativasAprovacao: false,
	});
	const [dadosFiltrados, setDadosFiltrados] = useState<TObrigacao[]>(obrigacoes);
	const [painelFiltrosSelecionadoVisivel, setPainelFiltrosSelecionadoVisivel] = useState(true);

	const alternarVisibilidadePainel = () => {
		setPainelFiltrosSelecionadoVisivel((prev) => !prev);
	};

	const exportToXLSX = () => {
		const headers = Object.keys(columnVisibility)
			.filter((key) => columnVisibility[key as keyof TObrigacao])
			.map((key) => columnNames[key as keyof TObrigacao] || key);

		const dadosParaExportar = dadosFiltrados?.length ? dadosFiltrados : obrigacoes;

		const dadosVisiveis = dadosParaExportar.map((item) => {
			return Object.keys(columnVisibility).reduce(
				(acc: Record<string, any>, key) => {
					if (columnVisibility[key]) {
						switch (key) {
							case 'aprovadores':
								acc[key] = item.aprovadores.map((aprov) => aprov.funcionario.nome).join(', ');
								break;

							case 'responsaveis':
								acc[key] = item.responsaveis.map((resp) => resp.funcionario.nome).join(', ');
								break;

							case 'situacao':
								if (!item.ativa) {
									acc[key] = 'Cancelada';
									break;
								}

								acc[key] = situacaoText[item.situacao as EnumSituacaoAcaoObrigacao] || 'Sem informação';
								break;

							case 'dataCriacao':
								acc[key] = formatDate(item.dataCriacao) || 'Sem informação';
								break;

							case 'dataFinal':
								acc[key] = formatDate(item.dataFinal) || 'Sem informação';
								break;

							case 'dataConclusao':
								acc[key] = item.dataConclusao ? formatDate(item.dataConclusao) : 'Sem informação';
								break;

							case 'dataVencimento':
								acc[key] = formatDate(item.dataVencimento) || 'Sem informação';
								break;

							case 'antecedenciaEnvio':
								acc[key] =
									tipoAntencedenciaEmailText[item.regraEnvioEmail.antecedencia as EnumTipoAntencedenciaEmail] ||
									'Sem informação';
								break;

							case 'frequenciaEnvio':
								acc[key] =
									tipoFrequenciaEnvioText[
										item.regraEnvioEmail.frequenciaAntecedencia as EnumTipoEmailFrequenciaEnvio
									] || 'Sem informação';
								break;

							case 'frequenciaAposVencimento':
								acc[key] =
									tipoFrequenciaEnvioText[item.regraEnvioEmail.frequenciaVencida as EnumTipoEmailFrequenciaEnvio] ||
									'Sem informação';
								break;

							case 'ativa':
								acc[key] = item.ativa ? 'Ativa' : 'Inativa';
								break;

							case 'ehLegal':
								acc[key] = item.ehLegal ? 'Legal' : 'Própria';
								break;

							case 'pedidoAlteracaoDataFinal': {
								const justificativaAlteracao =
									item.pedidoAlteracaoDataFinal?.[0]?.justificativaDataFinal || 'Sem justificativa.';
								acc[key] = justificativaAlteracao;
								break;
							}

							case 'pedidoCancelarObrigacao': {
								const justificativaCancelamento =
									item.pedidoCancelarObrigacao?.[0]?.justificativaCancelamento || 'Sem justificativa.';
								acc[key] = justificativaCancelamento;
								break;
							}

							case 'justificativasAprovacao': {
								const justificativaNaoAprovacao =
									item.justificativasAprovacao?.[0]?.justificativaNaoAprovacao || 'Sem justificativa.';
								acc[key] = justificativaNaoAprovacao;
								break;
							}

							default:
								acc[key as keyof TObrigacao] = item[key as keyof TObrigacao];
								break;
						}
					}
					return acc;
				},
				{} as Record<string, any>,
			);
		});
		const data = dadosVisiveis.map((row) =>
			Object.keys(columnVisibility)
				.filter((key) => columnVisibility[key as keyof TObrigacao])
				.map((key) => row[key]),
		);
		const worksheet = XLSX.utils.aoa_to_sheet([headers, ...data]);
		const workbook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(workbook, worksheet, 'Relatório');
		XLSX.writeFile(workbook, 'Relatorio.xlsx');
	};

	const handleColumnVisibilityChange = (selectedColumns: string[]) => {
		setColumnVisibility((prevState) => {
			const newVisibility = Object.keys(prevState).reduce(
				(acc, key) => {
					acc[key] = selectedColumns.includes(key);
					return acc;
				},
				{} as Record<string, boolean>,
			);

			const selectedFriendlyNames = selectedColumns.map((column) => columnNames[column] || column);

			setTags(selectedFriendlyNames);

			return newVisibility;
		});
	};

	const fetchAllObrigacoesData = async () => {
		try {
			const obrigacoesResponse = await fetchObrigacoes();
			const obrigacoesData = obrigacoesResponse.data.result;
			setObrigacoes(obrigacoesData);
		} catch (error) {
			console.log('Erro ao buscar obrigações', error);
		} finally {
			setCarregando(false);
		}
	};

	const _obrigacoesService = new ObrigacaoService();
	const fetchObrigacoes = (): Promise<TApiResponse<TObrigacao[]>> => {
		return _obrigacoesService.getAllObrigacoes(empresaAtiva as string | number);
	};

	useEffect(() => {
		fetchAllObrigacoesData();
	}, [empresaAtiva]);

	const formatDate = (value: string | undefined) => {
		return value ? moment(value).format('DD/MM/YYYY') : 'Sem informação';
	};

	const getUniqueValuesFromColumn = (dataField: keyof TObrigacao): string[] => {
		const lista: string[] = Array.from(
			new Set(
				obrigacoes.flatMap((obrigacao) =>
					Array.isArray(obrigacao[dataField])
						? obrigacao[dataField].filter((item: any) => item.principal).map((item: any) => item.funcionario.nome)
						: [],
				),
			),
		);
		return lista;
	};

	const onTableChange = (pagination: any, filters: any, sorter: any, extra: { currentDataSource: TObrigacao[] }) => {
		setDadosFiltrados(extra.currentDataSource);
	};

	const situacaoText = {
		[EnumSituacaoAcaoObrigacao.Nenhum]: 'Nenhum',
		[EnumSituacaoAcaoObrigacao.NoPrazo]: 'No Prazo',
		[EnumSituacaoAcaoObrigacao.ConcluidaNoPrazo]: 'Concluída no Prazo',
		[EnumSituacaoAcaoObrigacao.Atrasada]: 'Atrasada',
		[EnumSituacaoAcaoObrigacao.ConcluidaComAtraso]: 'Concluída com Atraso',
	};

	const tipoAntencedenciaEmailText: Record<EnumTipoAntencedenciaEmail, string> = {
		[EnumTipoAntencedenciaEmail.Nenhum]: 'Nenhum',
		[EnumTipoAntencedenciaEmail.UmDia]: 'Um dia',
		[EnumTipoAntencedenciaEmail.TresDias]: 'Três dias',
		[EnumTipoAntencedenciaEmail.UmaSemana]: 'Uma semana',
		[EnumTipoAntencedenciaEmail.DuasSemanas]: 'Duas semanas',
		[EnumTipoAntencedenciaEmail.UmMes]: 'Um mês',
		[EnumTipoAntencedenciaEmail.Criacao]: 'Criação',
		[EnumTipoAntencedenciaEmail.SomenteVencimento]: 'Somente no vencimento',
	};

	const tipoFrequenciaEnvioText: Record<EnumTipoEmailFrequenciaEnvio, string> = {
		[EnumTipoEmailFrequenciaEnvio.Nenhum]: 'Nenhum',
		[EnumTipoEmailFrequenciaEnvio.Diario]: 'Diário',
		[EnumTipoEmailFrequenciaEnvio.Semanal]: 'Semanal',
		[EnumTipoEmailFrequenciaEnvio.Quinzenal]: 'Quinzenal',
		[EnumTipoEmailFrequenciaEnvio.Mensal]: 'Mensal',
		[EnumTipoEmailFrequenciaEnvio.Unica]: 'Única',
	};

	const columns: ITableProps<TObrigacao>['columns'] = [
		{
			title: columnNames['nome'],
			dataIndex: 'nome',
			width: 200,
			ellipsis: true,
			key: '2',
			render: (text: string | undefined) =>
				columnVisibility.nome ? (
					<div style={{ maxWidth: '300px' }}>
						<Truncate text={text || 'Sem informação'} />
					</div>
				) : (
					'Sem informação'
				),
			sorter: (a: TObrigacao, b: TObrigacao) => a.nome?.localeCompare(b.nome || '') || 0,
			sortDirections: ['descend', 'ascend'] as SortOrder[],
			filterDropdown: createTextFilter('Buscar nome'),
			onFilter: createTextOnFilter('nome'),
		},
		{
			title: columnNames['codigo'],
			dataIndex: 'codigo',
			width: 140,
			ellipsis: true,
			key: '3',
			sorter: (a: TObrigacao, b: TObrigacao) => Number(a.codigo) - Number(b.codigo),
			sortDirections: ['descend', 'ascend'] as SortOrder[],
			render: (text: number | undefined) => (columnVisibility.codigo ? text || 'Sem informação' : 'Sem informação'),
			filterDropdown: createTextFilter('Buscar código'),
			onFilter: createTextOnFilter('codigo'),
		},
		{
			title: columnNames['dataCriacao'],
			dataIndex: 'dataCriacao',
			width: 200,
			render: (text: string | undefined) => (columnVisibility.dataCriacao ? formatDate(text) : 'Sem informação'),
			sorter: (a: TObrigacao, b: TObrigacao) => (dayjs(a.dataCriacao).isBefore(dayjs(b.dataCriacao)) ? -1 : 1),
			sortDirections: ['descend', 'ascend'] as SortOrder[],
			filterDropdown: createDateFilter(),
			onFilter: createDateOnFilter('dataCriacao'),
			key: '4',
		},
		{
			title: columnNames['dataVencimento'],
			dataIndex: 'dataVencimento',
			width: 200,
			render: (text: string | undefined) => (columnVisibility.dataVencimento ? formatDate(text) : 'Sem informação'),
			sorter: (a: TObrigacao, b: TObrigacao) => (dayjs(a.dataVencimento).isBefore(dayjs(b.dataVencimento)) ? -1 : 1),
			sortDirections: ['descend', 'ascend'] as SortOrder[],
			filterDropdown: createDateFilter(),
			onFilter: createDateOnFilter('dataVencimento'),
			key: '5',
		},
		{
			title: columnNames['ativa'],
			dataIndex: 'ativa',
			width: 100,
			filters: [
				{ text: 'Ativa', value: true },
				{ text: 'Inativa', value: false },
			],
			onFilter: (value: any, record: TObrigacao) => record.ativa === value,
			render: (text: boolean | undefined) => (columnVisibility.ativa ? (text ? 'Ativa' : 'Inativa') : 'Sem informação'),
			key: '7',
		},
		{
			title: columnNames['ehLegal'],
			dataIndex: 'ehLegal',
			width: 100,
			filters: [
				{ text: 'Legal', value: true },
				{ text: 'Própria', value: false },
			],
			onFilter: (value: any, record: TObrigacao) => record.ehLegal === value,
			render: (text: boolean | undefined) =>
				columnVisibility.ehLegal ? (text ? 'Legal' : 'Própria') : 'Sem informação',
			key: '8',
		},
		{
			title: columnNames['aprovadores'],
			dataIndex: 'aprovadores',
			width: 200,
			render: (text: any) => {
				if (!columnVisibility.aprovadores) return 'Sem informação';
				const principalAprovador = text?.find((item: any) => item.principal);
				return principalAprovador ? principalAprovador.funcionario.nome : 'Sem informação';
			},
			key: '9',
			filters: createFilterList(getUniqueValuesFromColumn('aprovadores')),
			onFilter: (value: any, record: TObrigacao) => record.aprovadores[0]?.funcionario.nome === value,
		},
		{
			title: columnNames['responsaveis'],
			dataIndex: 'responsaveis',
			width: 200,
			render: (text: any) => {
				if (!columnVisibility.responsaveis) return 'Sem informação';
				const principalResponsavel = text?.find((item: any) => item.principal);
				return principalResponsavel ? principalResponsavel.funcionario.nome : 'Sem informação';
			},
			filters: createFilterList(getUniqueValuesFromColumn('responsaveis')),
			onFilter: (value: any, record: TObrigacao) => record.responsaveis[0]?.funcionario.nome === value,
			key: '10',
		},
		{
			title: columnNames['situacao'],
			dataIndex: 'situacao',
			width: 140,
			render: (text: number | undefined, record: TObrigacao) =>
				!record.ativa
					? 'Cancelada'
					: columnVisibility.situacao
						? situacaoText[text as EnumSituacaoAcaoObrigacao]
						: 'Sem informação',
			key: '11',
			filters: [
				{ text: 'No prazo', value: EnumSituacaoAcaoObrigacao.NoPrazo },
				{ text: 'Concluída no prazo', value: EnumSituacaoAcaoObrigacao.ConcluidaNoPrazo },
				{ text: 'Atrasada', value: EnumSituacaoAcaoObrigacao.Atrasada },
				{ text: 'Concluída com atraso', value: EnumSituacaoAcaoObrigacao.ConcluidaComAtraso },
				{ text: 'Cancelada', value: 'cancelada' },
			],
			onFilter: (value: any, record: TObrigacao) => {
				if (value === 'cancelada') {
					return !record.ativa;
				}
				return record.situacao === value; 
			},
			sorter: (a: any, b: any) => (a.situacao || 0) - (b.situacao || 0),
		},
		{
			title: columnNames['dataConclusao'],
			dataIndex: 'dataConclusao',
			width: 200,
			render: (text: string | undefined) => (columnVisibility.dataConclusao ? formatDate(text) : 'Sem informação'),
			sorter: (a: TObrigacao, b: TObrigacao) => (dayjs(a.dataConclusao).isBefore(dayjs(b.dataConclusao)) ? -1 : 1),
			sortDirections: ['descend', 'ascend'] as SortOrder[],
			filterDropdown: createDateFilter(),
			onFilter: createDateOnFilter('dataConclusao'),
			key: '5',
		},
		{
			title: columnNames['acompanhamento'],
			dataIndex: 'acompanhamento',
			width: 200,
			render: (text: string | undefined) =>
				columnVisibility.acompanhamento ? (
					<div style={{ maxWidth: '300px' }}>
						<Truncate text={text || 'Sem informação'} />
					</div>
				) : (
					'Sem informação'
				),
			key: '12',
			filterDropdown: createTextFilter('Buscar acompanhamento'),
			onFilter: createTextOnFilter('acompanhamento'),
		},
		{
			title: columnNames['prazoLegal'],
			dataIndex: 'prazoLegal',
			width: 150,
			render: (text: string | undefined) =>
				columnVisibility.prazoLegal ? (
					<div style={{ maxWidth: '300px' }}>
						<Truncate text={text || 'Sem informação'} />
					</div>
				) : (
					'Sem informação'
				),
			key: '13',
			filterDropdown: createTextFilter('Buscar prazo legal'),
			onFilter: createTextOnFilter('prazoLegal'),
			sorter: (a: TObrigacao, b: TObrigacao) => (a.prazoLegal || '').localeCompare(b.prazoLegal || ''),
		},
		{
			title: columnNames['temporalidade'],
			dataIndex: 'temporalidade',
			width: 150,
			render: (text: string | undefined) =>
				columnVisibility.temporalidade ? (
					<div style={{ maxWidth: '300px' }}>
						<Truncate text={text || 'Sem informação'} />
					</div>
				) : (
					'Sem informação'
				),
			key: '14',
			filterDropdown: createTextFilter('Buscar temporalidade'),
			onFilter: createTextOnFilter('temporalidade'),
			sorter: (a: TObrigacao, b: TObrigacao) => (a.temporalidade || '').localeCompare(b.temporalidade || ''),
		},
		{
			title: columnNames['fundamentacaoLegal'],
			dataIndex: 'fundamentacaoLegal',
			width: 200,
			render: (text: string | undefined) =>
				columnVisibility.fundamentacaoLegal ? (
					<div style={{ maxWidth: '300px' }}>
						<Truncate text={text || 'Sem informação'} />
					</div>
				) : (
					'Sem informação'
				),
			key: '15',
			filterDropdown: createTextFilter('Buscar fundamentação legal'),
			sorter: (a: TObrigacao, b: TObrigacao) => (a.fundamentacaoLegal || '').localeCompare(b.fundamentacaoLegal || ''),
		},
		{
			title: columnNames['formaCumprimento'],
			dataIndex: 'formaCumprimento',
			width: 220,
			render: (text: string | undefined) =>
				columnVisibility.formaCumprimento ? (
					<div style={{ maxWidth: '300px' }}>
						<Truncate text={text || 'Sem informação'} />
					</div>
				) : (
					'Sem informação'
				),
			key: '16',
			filterDropdown: createTextFilter('Buscar forma de cumprimento'),
			onFilter: createTextOnFilter('formaCumprimento'),
			sorter: (a: TObrigacao, b: TObrigacao) => (a.formaCumprimento || '').localeCompare(b.formaCumprimento || ''),
		},
		{
			title: columnNames['dataFinal'],
			dataIndex: 'dataFinal',
			width: 150,
			render: (text: string | undefined) => (columnVisibility.dataFinal ? formatDate(text) : 'Sem informação'),
			sorter: (a: TObrigacao, b: TObrigacao) => {
				const dateA = dayjs(a.dataFinal);
				const dateB = dayjs(b.dataFinal);
				return dateA.isBefore(dateB) ? -1 : 1;
			},
			sortDirections: ['descend', 'ascend'] as SortOrder[],
			filterDropdown: createDateFilter(),
			onFilter: createDateOnFilter('dataFinal'),
			key: '17',
		},
		{
			title: columnNames['observacao'],
			dataIndex: 'observacao',
			width: 200,
			render: (text: string | undefined) =>
				columnVisibility.observacao ? (
					<div style={{ maxWidth: '300px' }}>
						<Truncate text={text || 'Sem informação'} />
					</div>
				) : (
					'Sem informação'
				),
			key: '18',
			filterDropdown: createTextFilter('Buscar observação'),
			onFilter: createTextOnFilter('observacao'),
			sorter: (a: TObrigacao, b: TObrigacao) => (a.observacao || '').localeCompare(b.observacao || ''),
		},
		{
			title: columnNames['frequenciaEnvio'],
			dataIndex: 'regraEnvioEmail',
			width: 200,
			render: (text: string, record: TObrigacao) => {
				if (!columnVisibility.frequenciaEnvio) return 'Sem informação';
				const frequenciaAntecedencia = record.regraEnvioEmail?.frequenciaAntecedencia;

				return frequenciaAntecedencia !== undefined
					? tipoAntencedenciaEmailText[frequenciaAntecedencia]
					: 'Sem informação';
			},
			filters: [
				{ text: 'Nenhum', value: EnumTipoEmailFrequenciaEnvio.Nenhum },
				{ text: 'Diário', value: EnumTipoEmailFrequenciaEnvio.Diario },
				{ text: 'Semanal', value: EnumTipoEmailFrequenciaEnvio.Semanal },
				{ text: 'Quinzenal', value: EnumTipoEmailFrequenciaEnvio.Quinzenal },
				{ text: 'Mensal', value: EnumTipoEmailFrequenciaEnvio.Mensal },
				{ text: 'Única', value: EnumTipoEmailFrequenciaEnvio.Unica },
			],
			onFilter: (value: any, record: TObrigacao) => record.regraEnvioEmail.frequenciaAntecedencia === value,
			sorter: (a: TObrigacao, b: TObrigacao) => {
				const freqA = a.regraEnvioEmail?.frequenciaAntecedencia ?? EnumTipoEmailFrequenciaEnvio.Nenhum;
				const freqB = b.regraEnvioEmail?.frequenciaAntecedencia ?? EnumTipoEmailFrequenciaEnvio.Nenhum;
				return freqA - freqB;
			},
			key: '19',
		},
		{
			title: columnNames['frequenciaEnvio'],
			dataIndex: 'frequenciaEnvio',
			width: 200,
			render: (text: any, record: TObrigacao) => {
				if (!columnVisibility.frequenciaEnvio || !record.regraEnvioEmail) return 'Sem informação';
				const { frequenciaAntecedencia } = record.regraEnvioEmail;
				return tipoFrequenciaEnvioText[frequenciaAntecedencia as EnumTipoEmailFrequenciaEnvio] || 'Sem informação';
			},
			filters: [
				{ text: 'Nenhum', value: EnumTipoEmailFrequenciaEnvio.Nenhum },
				{ text: 'Diário', value: EnumTipoEmailFrequenciaEnvio.Diario },
				{ text: 'Semanal', value: EnumTipoEmailFrequenciaEnvio.Semanal },
				{ text: 'Quinzenal', value: EnumTipoEmailFrequenciaEnvio.Quinzenal },
				{ text: 'Mensal', value: EnumTipoEmailFrequenciaEnvio.Mensal },
				{ text: 'Única', value: EnumTipoEmailFrequenciaEnvio.Unica },
			],
			onFilter: (value: any, record: TObrigacao) => record.regraEnvioEmail.frequenciaAntecedencia === value,
			sorter: (a: TObrigacao, b: TObrigacao) => {
				const freqA = a.regraEnvioEmail?.frequenciaAntecedencia ?? EnumTipoEmailFrequenciaEnvio.Nenhum;
				const freqB = b.regraEnvioEmail?.frequenciaAntecedencia ?? EnumTipoEmailFrequenciaEnvio.Nenhum;
				return freqA - freqB;
			},
			key: '20',
		},
		{
			title: columnNames['antecedenciaEnvio'],
			dataIndex: 'antecedenciaEnvio',
			width: 220,
			render: (text: any, record: TObrigacao) => {
				if (!columnVisibility.antecedenciaEnvio || !record.regraEnvioEmail) return 'Sem informação';
				const { antecedencia } = record.regraEnvioEmail;
				return tipoAntencedenciaEmailText[antecedencia as EnumTipoAntencedenciaEmail] || 'Sem informação';
			},
			filters: [
				{ text: 'Um dia', value: EnumTipoAntencedenciaEmail.UmDia },
				{ text: 'Três dias', value: EnumTipoAntencedenciaEmail.TresDias },
				{ text: 'Uma semana', value: EnumTipoAntencedenciaEmail.UmaSemana },
				{ text: 'Duas semanas', value: EnumTipoAntencedenciaEmail.DuasSemanas },
				{ text: 'Um mês', value: EnumTipoAntencedenciaEmail.UmMes },
				{ text: 'Criação', value: EnumTipoAntencedenciaEmail.Criacao },
				{ text: 'Somente vencimento', value: EnumTipoAntencedenciaEmail.SomenteVencimento },
			],
			onFilter: (value: any, record: TObrigacao) => record.regraEnvioEmail.antecedencia === value,
			sorter: (a: TObrigacao, b: TObrigacao) => {
				const antecedenciaA = a.regraEnvioEmail?.antecedencia ?? EnumTipoAntencedenciaEmail.Nenhum;
				const antecedenciaB = b.regraEnvioEmail?.antecedencia ?? EnumTipoAntencedenciaEmail.Nenhum;
				return antecedenciaA - antecedenciaB;
			},
			key: '21',
		},
		{
			title: columnNames['frequenciaAposVencimento'],
			dataIndex: 'frequenciaAposVencimento',
			width: 300,
			render: (text: any, record: TObrigacao) => {
				if (!columnVisibility.frequenciaAposVencimento || !record.regraEnvioEmail) return 'Sem informação';
				const { frequenciaVencida } = record.regraEnvioEmail;
				return tipoFrequenciaEnvioText[frequenciaVencida as EnumTipoEmailFrequenciaEnvio] || 'Sem informação';
			},
			filters: [
				{ text: 'Nenhum', value: EnumTipoEmailFrequenciaEnvio.Nenhum },
				{ text: 'Diário', value: EnumTipoEmailFrequenciaEnvio.Diario },
				{ text: 'Semanal', value: EnumTipoEmailFrequenciaEnvio.Semanal },
				{ text: 'Quinzenal', value: EnumTipoEmailFrequenciaEnvio.Quinzenal },
				{ text: 'Mensal', value: EnumTipoEmailFrequenciaEnvio.Mensal },
				{ text: 'Única', value: EnumTipoEmailFrequenciaEnvio.Unica },
			],
			onFilter: (value: any, record: TObrigacao) => record.regraEnvioEmail.frequenciaVencida === value,
			sorter: (a: TObrigacao, b: TObrigacao) => {
				const freqA = a.regraEnvioEmail?.frequenciaVencida ?? EnumTipoEmailFrequenciaEnvio.Nenhum;
				const freqB = b.regraEnvioEmail?.frequenciaVencida ?? EnumTipoEmailFrequenciaEnvio.Nenhum;
				return freqA - freqB;
			},
			key: '22',
		},
		{
			title: columnNames['outrosDestinatarios'],
			dataIndex: 'outrosDestinatarios',
			width: 200,
			render: (text: any, record: TObrigacao) => {
				if (!columnVisibility.outrosDestinatarios) return 'Sem informação';
				const destinatarios = record.listaEmail
					.filter((email: any) => !email.somenteAtraso)
					.map((email: any) => email.funcionario.nome);

				return destinatarios.length > 0 ? destinatarios.join(', ') : 'Sem informação';
			},
			filterDropdown: createTextFilter('Buscar destinatário'),
			onFilter: (value: any, record: TObrigacao) => {
				const destinatarios = record.listaEmail
					.filter((email: any) => !email.somenteAtraso)
					.map((email: any) => email.funcionario.nome)
					.join(', ');

				return destinatarios.toLowerCase().includes(value.toLowerCase());
			},
			sorter: (a: TObrigacao, b: TObrigacao) => {
				const destinatariosA = a.listaEmail
					.filter((email: any) => !email.somenteAtraso)
					.map((email: any) => email.funcionario.nome)
					.join(', ');
				const destinatariosB = b.listaEmail
					.filter((email: any) => !email.somenteAtraso)
					.map((email: any) => email.funcionario.nome)
					.join(', ');

				return destinatariosA.localeCompare(destinatariosB);
			},
			key: '23',
		},
		{
			title: columnNames['destinatariosAtraso'],
			dataIndex: 'destinatariosAtraso',
			width: 280,
			render: (text: any, record: TObrigacao) => {
				if (!columnVisibility.destinatariosAtraso) return 'Sem informação';
				const destinatariosAtraso = record.listaEmail
					.filter((email: any) => email.somenteAtraso)
					.map((email: any) => email.funcionario.nome);

				return destinatariosAtraso.length > 0 ? destinatariosAtraso.join(', ') : 'Sem informação';
			},
			filterDropdown: createTextFilter('Buscar destinatário'),
			onFilter: (value: any, record: TObrigacao) => {
				const destinatarios = record.listaEmail
					.filter((email: any) => email.somenteAtraso)
					.map((email: any) => email.funcionario.nome)
					.join(', ');

				return destinatarios.toLowerCase().includes(value.toLowerCase());
			},
			sorter: (a: TObrigacao, b: TObrigacao) => {
				const destinatariosA = a.listaEmail
					.filter((email: any) => email.somenteAtraso)
					.map((email: any) => email.funcionario.nome)
					.join(', ');
				const destinatariosB = b.listaEmail
					.filter((email: any) => email.somenteAtraso)
					.map((email: any) => email.funcionario.nome)
					.join(', ');

				return destinatariosA.localeCompare(destinatariosB);
			},
			key: '24',
		},
		{
			title: columnNames['pedidoAlteracaoDataFinal'],
			dataIndex: 'pedidoAlteracaoDataFinal',
			width: 340,
			render: (text: TPedidoAlteracaoDataFinal[] | undefined) =>
				columnVisibility.pedidoAlteracaoDataFinal ? (
					text && text.length > 0 ? (
						<div style={{ maxWidth: '300px' }}>
							<Truncate text={text[0]?.justificativaDataFinal || 'Sem justificativa.'} />
						</div>
					) : (
						'Sem justificativa.'
					)
				) : (
					'Sem informação'
				),
			key: '25',
			sortDirections: ['descend', 'ascend'] as SortOrder[],
			filterDropdown: createTextFilter('Buscar justificativa'),
			onFilter: (value: any, record: any) => {
				const justificativas = record.pedidoAlteracaoDataFinal || [];
				return justificativas.some((justificativa: TPedidoAlteracaoDataFinal) =>
					justificativa.justificativaDataFinal?.toLowerCase().includes(value.toLowerCase()),
				);
			},
			sorter: (a: any, b: any) => {
				const justificativaA =
					(a.pedidoAlteracaoDataFinal && a.pedidoAlteracaoDataFinal[0]?.justificativaDataFinal) || '';
				const justificativaB =
					(b.pedidoAlteracaoDataFinal && b.pedidoAlteracaoDataFinal[0]?.justificativaDataFinal) || '';

				return justificativaA.localeCompare(justificativaB);
			},
		},
		{
			title: columnNames['pedidoCancelarObrigacao'],
			dataIndex: 'pedidoCancelarObrigacao',
			width: 300,
			render: (text: TPedidoCancelarObrigacao[] | undefined) =>
				columnVisibility.pedidoCancelarObrigacao ? (
					text?.length ? (
						<div style={{ maxWidth: '300px' }}>
							<Truncate text={text[0]?.justificativaCancelamento || 'Sem justificativa.'} />
						</div>
					) : (
						'Sem justificativa.'
					)
				) : (
					'Sem informação'
				),
			key: '26',
			sortDirections: ['descend', 'ascend'] as SortOrder[],
			filterDropdown: createTextFilter('Buscar justificativa de cancelamento'),
			onFilter: (value: any, record: any) => {
				const justificativas = record.pedidoCancelarObrigacao || [];
				return justificativas.some((justificativa: TPedidoCancelarObrigacao) =>
					justificativa.justificativaCancelamento?.toLowerCase().includes(value.toLowerCase()),
				);
			},
			sorter: (a: any, b: any) => {
				const justificativaA =
					(a.pedidoCancelarObrigacao && a.pedidoCancelarObrigacao[0]?.justificativaCancelamento) || '';
				const justificativaB =
					(b.pedidoCancelarObrigacao && b.pedidoCancelarObrigacao[0]?.justificativaCancelamento) || '';

				return justificativaA.localeCompare(justificativaB);
			},
		},
		{
			title: columnNames['justificativasAprovacao'],
			dataIndex: 'justificativasAprovacao',
			width: 300,
			render: (text: TJustificativasAprovacao[] | undefined) =>
				columnVisibility.justificativasAprovacao ? (
					text?.length ? (
						<Truncate text={text[0]?.justificativaNaoAprovacao || 'Sem justificativa.'} />
					) : (
						'Sem justificativa.'
					)
				) : (
					'Sem informação'
				),
			key: '27',
			sortDirections: ['descend', 'ascend'] as SortOrder[],
			filterDropdown: createTextFilter('Buscar justificativa'),
			onFilter: (value: any, record: any) => {
				const justificativas = record.justificativasAprovacao || [];
				return justificativas.some((justificativa: TJustificativasAprovacao) =>
					justificativa.justificativaNaoAprovacao?.toLowerCase().includes(value.toLowerCase()),
				);
			},
			sorter: (a: any, b: any) => {
				const justificativaA =
					(a.justificativasAprovacao && a.justificativasAprovacao[0]?.justificativaNaoAprovacao) || '';
				const justificativaB =
					(b.justificativasAprovacao && b.justificativasAprovacao[0]?.justificativaNaoAprovacao) || '';

				return justificativaA.localeCompare(justificativaB);
			},
		},
	].filter((col) => columnVisibility[col.dataIndex]);

	const columnOptions = Object.keys(columnVisibility).map((key) => ({
		label: columnNames[key],
		value: key,
	}));

	useEffect(() => {
		const initialTags = Object.keys(columnVisibility)
			.filter((key) => columnVisibility[key])
			.map((key) => columnNames[key] || key);
		setTags(initialTags);
	}, [columnVisibility]);

	const handleTagClose = (tag: string) => {
		setTags((prevTags) => {
			const newTags = prevTags.filter((t) => t !== tag);
			const originalColumn = Object.keys(columnNames).find((key) => columnNames[key] === tag) || tag;
			setColumnVisibility((prevVisibility) => ({
				...prevVisibility,
				[originalColumn]: false,
			}));
			return newTags;
		});
	};

	const onDragEnd = (result: any) => {
		if (!result.destination) return;

		const newTags = Array.from(tags);
		const [removed] = newTags.splice(result.source.index, 1);
		newTags.splice(result.destination.index, 0, removed);

		setTags(newTags);
	};

	const orderedColumns = tags
		.map((tag) => {
			const originalTag = Object.keys(columnNames).find((key) => columnNames[key] === tag) || tag;
			const column = columns.find((col) => {
				return (col as TableColumnType<TObrigacao>).dataIndex === originalTag;
			});
			return column;
		})
		.filter((col): col is TableColumnType<TObrigacao> => !!col);

	return (
		<Surface
			className={estilos.containerRelatorios}
			style={!painelFiltrosSelecionadoVisivel ? { paddingLeft: '42px' } : undefined}
		>
			<Row gutter={[16, 16]}>
				<Col xs={24} className={estilos.colRelatorio}>
					<Flex className={estilos.flexRelatorio}>
						<Typography.Title size='large'>Relatório de Obrigações</Typography.Title>
						<Button onClick={exportToXLSX}>
							<Flex gap={6}>
								<Icons.Download />
								Baixar relatório
							</Flex>
						</Button>
					</Flex>
				</Col>
				<Col style={{ padding: '0' }}>
					<Flex justify='space-between' gap={20}>
						{painelFiltrosSelecionadoVisivel && (
							<Col
								xxl={4}
								xl={6}
								lg={8}
								xs={24}
								sm={24}
								md={24}
								style={{
									background: '#ffff',
									borderRadius: '8px',
									position: 'relative',
								}}
							>
								<Flex align='center' gap='1rem' justify='center'>
									<Flex vertical gap={14} className={estilos.containerSelecaoFiltros}>
										<Flex vertical gap={4}>
											<Flex vertical gap={4}>
												<Typography.Title size='medium'>Seleção e ordenação de colunas</Typography.Title>

												{isSelectOpen ? (
													<Select
														className='multiSelectColumn'
														mode='multiple'
														open={isSelectOpen}
														style={{ width: '100%' }}
														placeholder='Selecione as colunas'
														options={columnOptions}
														value={Object.keys(columnVisibility).filter((key) => columnVisibility[key])}
														onChange={handleColumnVisibilityChange}
														onDropdownVisibleChange={(open) => setIsSelectOpen(open)}
														ref={inputRef}
													/>
												) : (
													<Button onClick={() => setIsSelectOpen(true)} style={{ width: '100%' }}>
														<Flex gap={4} align='center'>
															<Icons.FilterAlt />
															<Typography.Label size='medium'> Inserir nova coluna</Typography.Label>
														</Flex>
													</Button>
												)}
											</Flex>
										</Flex>

										<DragDropContext onDragEnd={onDragEnd}>
											<Droppable droppableId='droppable' direction='vertical'>
												{(provided) => (
													<div
														ref={provided.innerRef}
														{...provided.droppableProps}
														className={estilos.droppableContainer}
													>
														{tags.map((tag, index) => (
															<Draggable key={tag} draggableId={tag} index={index}>
																{(provided, snapshot) => (
																	<Tag
																		ref={provided.innerRef}
																		{...provided.draggableProps}
																		{...provided.dragHandleProps}
																		closable
																		onClose={() => handleTagClose(tag)}
																		className={snapshot.isDragging ? 'dragging' : ''}
																	>
																		<Flex
																			justify='space-between'
																			align='middle'
																			className={estilos.containerDragIndicador}
																		>
																			<Typography.Body size='small'>
																				{tag.charAt(0).toUpperCase() + tag.slice(1)}
																			</Typography.Body>
																			<DragIndicatorIcon style={{ fontSize: '16px' }} />
																		</Flex>
																	</Tag>
																)}
															</Draggable>
														))}
														{provided.placeholder}
													</div>
												)}
											</Droppable>
										</DragDropContext>
									</Flex>
								</Flex>

								<Flex justify='right'>
									<Tooltip placement='left' title={'Ocultar menu de seleção de colunas'}>
										<Button className={estilos.botaoOcultarMenu} onClick={alternarVisibilidadePainel}>
											<Flex className={estilos.flexRow}>
												<Typography.Body className={estilos.tipografiaOcultar} size='small'>
													Ocultar
												</Typography.Body>
												<MenuUnfoldOutlined onClick={alternarVisibilidadePainel} className={estilos.iconeOcultar} />
											</Flex>
										</Button>
									</Tooltip>
								</Flex>
							</Col>
						)}

						<Col
							xxl={painelFiltrosSelecionadoVisivel ? 20 : 24}
							xl={painelFiltrosSelecionadoVisivel ? 18 : 24}
							lg={painelFiltrosSelecionadoVisivel ? 16 : 24}
							xs={24}
							sm={24}
							md={24}
							style={{ padding: '0' }}
						>
							<Table
								dataSource={obrigacoes}
								columns={orderedColumns}
								pagination={{ position: ['bottomCenter'] }}
								style={painelFiltrosSelecionadoVisivel ? { paddingRight: '20px' } : undefined}
								rowKey='id'
								scroll={{ x: 'max-content', y: 600 }}
								expandable={{ showExpandColumn: false }}
								loading={carregando}
								onChange={onTableChange}
							/>
						</Col>

						{!painelFiltrosSelecionadoVisivel && (
							<Tooltip placement='left' title={'Exibir seleção e ordenação de colunas'}>
								<Button className={estilos.botaoCustomizado} onClick={alternarVisibilidadePainel}>
									<Flex className={estilos.flexColuna}>
										<MenuFoldOutlined />
										<Typography.Body className={estilos.tipografiaCustomizada} size='small'>
											Seleção e Ordenação
										</Typography.Body>
									</Flex>
								</Button>
							</Tooltip>
						)}
					</Flex>
				</Col>
			</Row>
		</Surface>
	);
};

export default RelatorioObrigacoes;
