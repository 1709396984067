import React from 'react';
import { Col, Icons, Row, Typography, Tag, VariantType } from '@jcm/design-system';
import style from './index.module.scss';
import { Popover } from 'antd';

interface CabecalhoProps {
	dataVencimento: string;
	prorrogada: boolean;
	estadoStatusAlterarData: number;
	estadoStatusAprovada: number;
	estadoStatusCancelamento: number;
	ehLegal: boolean;
}

const iconDisabled = {
	fontSize: '20px',
	color: '#A0A0A0',
	background: 'none',
	border: 'none',
	boxShadow: 'none',
};

const iconEnabled = {
	fontSize: '20px',
	color: '#006A6A',
	background: 'none',
	border: 'none',
	boxShadow: 'none',
};

const converterData = (dataString: string): Date => {
	const [dia, mes, ano] = dataString.split('/').map(Number);
	return new Date(ano, mes - 1, dia);
};

const verificarDataAtrasada = (dataVencimento: Date): boolean => {
	const dataAtual = new Date();
	const inicioDiaAtual = new Date(dataAtual.getFullYear(), dataAtual.getMonth(), dataAtual.getDate());
	return dataVencimento < inicioDiaAtual;
};

const obterLabels = (
	prorrogada: boolean,
	estadoStatusAlterarData: number,
	estadoStatusAprovada: number,
	estadoStatusCancelamento: number,
): { text: string; icon: React.ReactNode; cor: VariantType }[] => {
	const textoStatus: { text: string; icon: React.ReactNode; cor: VariantType }[] = [];

	if (prorrogada)
		textoStatus.push({
			text: 'Postergada',
			icon: <Icons.Error />,
			cor: 'roxo',
		});

	// Lógica para alteração da data
	if (estadoStatusAlterarData && estadoStatusAprovada !== 2) {
		switch (estadoStatusAlterarData) {
			case 1:
				textoStatus.push({
					text: 'Aguardando aprovação da alteração da data de vencimento',
					icon: <Icons.Error />,
					cor: 'default',
				});
				break;
			case 2:
				textoStatus.push({
					text: 'Alteração da data de vencimento aprovada',
					icon: <Icons.Check color='green' className={style.iconeAprovado} />,
					cor: 'verde',
				});
				break;
			case 3:
				textoStatus.push({
					text: 'Alteração da data de vencimento não aprovada',
					icon: <Icons.Error className={style.iconeReprovado} />,
					cor: 'magenta',
				});
				break;
			default:
				break;
		}
	}

	// Lógica para aprovação
	switch (estadoStatusAprovada) {
		case 1:
			textoStatus.push({
				text: 'Aguardando aprovação da conclusão',
				icon: <Icons.Error />,
				cor: 'tertiary',
			});
			break;
		case 2:
			textoStatus.push({
				text: 'Conclusão aprovada',
				icon: <Icons.Check color='green' className={style.iconeAprovado} />,
				cor: 'verde',
			});
			break;
		case 3:
			textoStatus.push({
				text: 'Conclusão não aprovada',
				icon: <Icons.Error className={style.iconeReprovado} />,
				cor: 'magenta',
			});
			break;
		default:
			break;
	}

	// Lógica para cancelamento
	if (estadoStatusCancelamento && estadoStatusAprovada !== 2) {
		switch (estadoStatusCancelamento) {
			case 1:
				textoStatus.push({
					text: 'Aguardando aprovação do cancelamento',
					icon: <Icons.Error />,
					cor: 'amarelo',
				});
				break;
			case 2:
				textoStatus.push({
					text: 'Cancelamento aprovado',
					icon: <Icons.Check className={style.iconeAprovado} />,
					cor: 'verde',
				});
				break;
			case 3:
				textoStatus.push({
					text: 'Cancelamento não aprovado',
					icon: <Icons.Error className={style.iconeReprovado} />,
					cor: 'magenta',
				});
				break;
			default:
				break;
		}
	}

	return textoStatus;
};

const Cabecalho: React.FC<CabecalhoProps> = ({
	dataVencimento,
	prorrogada,
	estadoStatusAlterarData,
	estadoStatusAprovada,
	estadoStatusCancelamento,
	ehLegal,
}) => {
	const dataVenc = converterData(dataVencimento);
	const obrigacaoAtrasada = verificarDataAtrasada(dataVenc);
	const labels = obterLabels(prorrogada, estadoStatusAlterarData, estadoStatusAprovada, estadoStatusCancelamento);

	return (
		<Col span={24}>
			<Row justify='space-between'>
				<Typography.Title className={`${style.dataCartao} ${obrigacaoAtrasada ? style.dataCartaoAtrasado : ''}`}>
					{dataVencimento}
				</Typography.Title>

				<Row gutter={[16, 0]} align='middle' className={style.containerLabels}>
					{labels.length > 0 && (
						<>
							{labels.map(({ text, icon, cor }, index) => (
								<Tag key={index} bordered icon={icon} id='Tag' variant={cor}>
									{text}
								</Tag>
							))}
						</>
					)}
					<Popover placement='top' content={ehLegal ? 'Obrigação legal' : 'Obrigação própria'}>
						<Col>
							<Icons.Balance variant='outlined' style={ehLegal ? iconEnabled : iconDisabled} />
						</Col>
					</Popover>
				</Row>
			</Row>
		</Col>
	);
};

export default Cabecalho;
