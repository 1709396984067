export function listarIntervaloAnoAtual(): { dataInicio: string; dataFim: string } {
	const hoje = new Date();

	// Data de início: mesma data do ano anterior
	const dataInicio = new Date(hoje.getFullYear() - 1, hoje.getMonth(), hoje.getDate());

	// Data de fim: mesma data do ano seguinte
	const dataFim = new Date(hoje.getFullYear() + 1, hoje.getMonth(), hoje.getDate());

	const formatarData = (data: Date): string => {
		const dia = String(data.getDate()).padStart(2, '0');
		const mes = String(data.getMonth() + 1).padStart(2, '0');
		const ano = data.getFullYear();
		return `${ano}-${mes}-${dia}`;
	};

	return {
		dataInicio: formatarData(dataInicio),
		dataFim: formatarData(dataFim),
	};
}
