/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect, Key } from 'react';
import { FilterFilled } from '@ant-design/icons';
import { Table, Input } from 'antd';
import { TObrigacao } from '../../../ts/types/Obrigacoes';
import estilo from './index.module.scss';
import { useGovernancaContext } from '../../../../../../context/GovernancaContext';
import { PerfilFuncionario } from '../../../ts/enums/FuncaoEnum';
import { Button, Select } from '@jcm/design-system';
import TruncatedTextTooltip from '../../../../../../shared/components/truncatedTextTooltip/TruncatedTextTooltip';
import { RangePicker } from '@jcm/design-system';

interface OperacoesTabelaProps {
	obrigacoes: TObrigacao[];
	empresaId: string | number;
	onObrigacaoChange: (obrigacaoAtualizada: TObrigacao) => void;
	onAtualizar?: () => void;
	onSelectChange: (selecionados: number[]) => void;
	loading: boolean;
}

const OperacoesTabela: React.FC<OperacoesTabelaProps> = ({ obrigacoes, onSelectChange, loading }) => {
	const { usuario } = useGovernancaContext();
	const [paginaAtual, setPaginaAtual] = useState(1);
	const [tamanhoPagina, setTamanhoPagina] = useState(10);
	const [chavesSelecionadas, setChavesSelecionadas] = useState<number[]>([]);
	const [filteredData, setFilteredData] = useState<TObrigacao[]>(obrigacoes);
	const [marcarTodos, setMarcarTodos] = useState(false);
	const [filterText, setFilterText] = useState<{ [key: string]: any }>({
		codigo: '',
		nome: '',
		responsavel: '',
		aprovador: '',
		dataVencimento: [],
	});

	useEffect(() => {
		const filtered = obrigacoes.filter((item) => {
			const isResponsavel = item.responsaveis.some(
				(r) => r.principal && r.funcionario.funcionarioId === usuario?.funcionarioId,
			);

			const podeVer =
				usuario?.funcao === PerfilFuncionario.AdministradorEmpresa ||
				usuario?.funcao === PerfilFuncionario.AdministradorSistema ||
				isResponsavel;

			return podeVer;
		});

		setFilteredData(filtered);
	}, [obrigacoes, usuario]);

	useEffect(() => {
		setChavesSelecionadas([]);
		setMarcarTodos(false);
		onSelectChange([]);
	}, [obrigacoes]);

	useEffect(() => {
		setMarcarTodos(chavesSelecionadas.length === filteredData.length);
	}, [chavesSelecionadas, filteredData]);

	const aoSelecionar = (chaves: React.Key[]) => {
		setChavesSelecionadas(chaves as number[]);
		onSelectChange(chaves as number[]);
	};

	const selecionarTodos = () => {
		if (!marcarTodos) {
			const allIds = filteredData.map((item) => item.id);
			setChavesSelecionadas(allIds);
			setMarcarTodos(true);
			onSelectChange(allIds);
		} else {
			setChavesSelecionadas([]);
			setMarcarTodos(false);
			onSelectChange([]);
		}
	};

	const removerSelecoes = (removidas: React.Key[]) => {
		setChavesSelecionadas((prev) => {
			const atualizadas = prev.filter((chave) => !removidas.includes(chave));
			onSelectChange(atualizadas);
			return atualizadas;
		});
	};

	const criarFiltroTextoDropdown = (placeholder: string, columnKey: string) => (
		<div style={{ padding: 8 }}>
			<Input
				placeholder={placeholder}
				value={filterText[columnKey]}
				onChange={(e) => setFilterText((prev) => ({ ...prev, [columnKey]: e.target.value }))}
				style={{ marginBottom: 8, display: 'block' }}
			/>
			<Button
				type='filled'
				onClick={() => setFilterText((prev) => ({ ...prev, [columnKey]: '' }))}
				style={{ width: '100%' }}
			>
				Resetar
			</Button>
		</div>
	);

	const criarFiltroSelectDropdown = (placeholder: string, columnKey: string, options: string[]) => (
		<div style={{ padding: 8 }}>
			<Select
				showSearch
				placeholder={placeholder}
				style={{ width: '100%' }}
				options={options.map((option) => ({ label: option, value: option }))}
				onChange={(value) => setFilterText((prev) => ({ ...prev, [columnKey]: value }))}
				allowClear
			/>
		</div>
	);

	const criarFiltroRangePickerDropdown = (columnKey: string) => (
		<div style={{ padding: 8 }}>
			<RangePicker
				onChange={(dates) => setFilterText((prev) => ({ ...prev, [columnKey]: dates }))}
				value={filterText[columnKey]}
				format='DD/MM/YYYY'
				style={{ marginBottom: 8, display: 'block', width: '100%' }}
			/>
			<Button
				type='filled'
				onClick={() => setFilterText((prev) => ({ ...prev, [columnKey]: [] }))}
				style={{ width: '100%' }}
			>
				Resetar
			</Button>
		</div>
	);

	const colunas = [
		{
			title: 'Código',
			dataIndex: 'codigo',
			key: 'codigo',
			width: '10%',
			sorter: (a: TObrigacao, b: TObrigacao) => Number(a.codigo) - Number(b.codigo),
			filterDropdown: () => criarFiltroTextoDropdown('Buscar ID', 'codigo'),
			filterIcon: (filtered: boolean) => <FilterFilled style={{ color: filtered ? '#1890ff' : undefined }} />,
			onFilter: (value: string | number | boolean | Key, record: TObrigacao) =>
				String(record.codigo).toLowerCase().includes(String(value).toLowerCase()),
			filteredValue: filterText['codigo'] ? [filterText['codigo']] : null,
		},
		{
			title: 'Descrição',
			dataIndex: 'nome',
			key: 'nome',
			width: '30%',
			render: (text: string) => <TruncatedTextTooltip text={text} maxWidth='300px' />,
			sorter: (a: TObrigacao, b: TObrigacao) => a.nome.localeCompare(b.nome),
			filterDropdown: () => criarFiltroTextoDropdown('Buscar descrição', 'nome'),
			filterIcon: (filtered: boolean) => <FilterFilled style={{ color: filtered ? '#1890ff' : undefined }} />,
			onFilter: (value: string | boolean | Key, record: TObrigacao) =>
				record.nome ? record.nome.toLowerCase().includes(String(value).toLowerCase()) : false,
			filteredValue: filterText['nome'] ? [filterText['nome']] : null,
		},
		{
			title: 'Responsável',
			dataIndex: 'responsavel',
			key: 'responsavel',
			width: '25%',
			render: (_: unknown, obrigacao: TObrigacao) =>
				obrigacao.responsaveis.find((r) => r.principal)?.funcionario.nome || 'N/A',
			sorter: (a: TObrigacao, b: TObrigacao) => {
				const nomeA = a.responsaveis.find((r) => r.principal)?.funcionario.nome || '';
				const nomeB = b.responsaveis.find((r) => r.principal)?.funcionario.nome || '';
				return nomeA.localeCompare(nomeB);
			},
			filterDropdown: () =>
				criarFiltroSelectDropdown(
					'Selecionar Responsável',
					'responsavel',
					Array.from(new Set(obrigacoes.flatMap((obrigacao) => obrigacao.responsaveis.map((r) => r.funcionario.nome)))),
				),
			onFilter: (value: string | boolean | Key, record: TObrigacao) => {
				const nomeResponsavel = record.responsaveis.find((r) => r.principal)?.funcionario.nome || '';
				return nomeResponsavel === value;
			},
			filteredValue: filterText['responsavel'] ? [filterText['responsavel']] : null,
		},
		{
			title: 'Aprovador',
			dataIndex: 'aprovador',
			key: 'aprovador',
			width: '25%',
			render: (_: unknown, obrigacao: TObrigacao) =>
				obrigacao.aprovadores.find((a) => a.principal)?.funcionario.nome || 'N/A',
			sorter: (a: TObrigacao, b: TObrigacao) => {
				const nomeA = a.aprovadores.find((a) => a.principal)?.funcionario.nome || '';
				const nomeB = b.aprovadores.find((a) => a.principal)?.funcionario.nome || '';
				return nomeA.localeCompare(nomeB);
			},
			filterDropdown: () =>
				criarFiltroSelectDropdown(
					'Selecionar Aprovador',
					'aprovador',
					Array.from(new Set(obrigacoes.flatMap((obrigacao) => obrigacao.aprovadores.map((a) => a.funcionario.nome)))),
				),
			onFilter: (value: string | boolean | Key, record: TObrigacao) => {
				const nomeAprovador = record.aprovadores.find((a) => a.principal)?.funcionario.nome || '';
				return nomeAprovador === value;
			},
			filteredValue: filterText['aprovador'] ? [filterText['aprovador']] : null,
		},
		{
			title: 'Vencimento',
			dataIndex: 'dataVencimento',
			key: 'dataVencimento',
			width: '10%',
			render: (text: string) => (
				<div className={estilo.dataVencimentoContainer}>
					<span className={estilo.dataVencimentoText}>
						{new Date(text).toLocaleDateString('pt-BR', {
							day: '2-digit',
							month: '2-digit',
							year: 'numeric',
						})}
					</span>
				</div>
			),
			sorter: (a: TObrigacao, b: TObrigacao) =>
				new Date(a.dataVencimento).getTime() - new Date(b.dataVencimento).getTime(),
			filterDropdown: () => criarFiltroRangePickerDropdown('dataVencimento'),
			filterIcon: (filtered: boolean) => <FilterFilled style={{ color: filtered ? '#1890ff' : undefined }} />,
			onFilter: (_: unknown, record: TObrigacao) => {
				const [start, end] = filterText['dataVencimento'] || [];
				if (!start || !end) return true;
				const recordDate = new Date(record.dataVencimento);
				return recordDate >= start.startOf('day').toDate() && recordDate <= end.endOf('day').toDate();
			},
			filteredValue:
				filterText['dataVencimento'] && filterText['dataVencimento'].length > 0 ? filterText['dataVencimento'] : null,
		},
	];

	return (
		<div className={estilo.tableContainer}>
			<div className={estilo.counterContainer}>
				<Button type='outlined' onClick={selecionarTodos} className={estilo.selectAllButton}>
					Selecionar todos os itens
				</Button>
				<div className={estilo.counterItemTodos}>
					<strong>Todos:</strong> {filteredData.length}
				</div>
				<div className={estilo.counterItemSelecionadas}>
					<strong>Selecionadas:</strong> {chavesSelecionadas.length}
				</div>
			</div>
			<Table
				loading={loading}
				rowSelection={{
					selectedRowKeys: chavesSelecionadas,
					onChange: (chaves) => {
						const desmarcadas = chavesSelecionadas.filter((key) => !chaves.includes(key));
						if (desmarcadas.length > 0) {
							removerSelecoes(desmarcadas);
						} else {
							aoSelecionar(chaves);
						}
					},
					onSelectAll: (selecionado, registrosSelecionados) => {
						if (selecionado) {
							const novasChaves = registrosSelecionados.map((r) => r.id);
							aoSelecionar(novasChaves);
						} else {
							const removidas = registrosSelecionados.map((r) => r.id);
							removerSelecoes(removidas);
						}
					},
				}}
				columns={colunas}
				dataSource={filteredData}
				pagination={{
					position: ['bottomCenter'],
					pageSize: tamanhoPagina,
					current: paginaAtual,
					onChange: (pagina) => setPaginaAtual(pagina),
					onShowSizeChange: (pagina, tamanho) => {
						setPaginaAtual(pagina);
						setTamanhoPagina(tamanho);
					},
				}}
				rowKey='id'
				expandable={{ showExpandColumn: false }}
			/>
		</div>
	);
};

export default OperacoesTabela;
