import React, { useState, useEffect } from 'react';
import { Box, Grid } from '@mui/material';
import EnvioEmails from './EnvioEmails/EnvioEmails';
import GerenciarObrigacao from './GerenciarObrigacao/GerenciarObrigacao';
import CalculoPeriodicidade from './CalculoPeriodicidade/CalculoPeriodicidade';
import { CriarAnexoPayload, CriarObrigacaoPayload, TObrigacao } from '../../../ts/types/Obrigacoes';
import ObrigacoesService from '../../../services/Obrigacoes/Obrigacoes.service';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import CustomHeader from './CustomHeaderForm/CustomHeader';
import { Button } from '@jcm/design-system';
import { useGovernancaContext } from '../../../../../../context/GovernancaContext';
import ModalAlterarData from './Acoes/ModalAlterarData/ModalAlterarData';
import ModalConcluirObrigacao from '../../../components/obrigacoes/ModalConcluirObrigacao/ModalConcluirObrigacao';
import CustomAlert from '../../../../../../shared/components/customAlert/customAlert';
import {
	obrigacaoEstaConcluida,
	usuarioEhAdmin,
	verificarAprovadorPrincipal,
	verificarResponsavelPrincipal,
} from '../../../utils/determinarAcessoObrigacoes';
import ModalCancelarObrigacao from './Acoes/ModalCancelarObrigacao/ModalCancelarObrigacao';
import LogsModal from './LogsModal/index';
import AcoesAnexo from './Acoes/AcoesAnexo';
import Anexos from './Anexos/Anexos';

interface FormObrigacoesProps {
	initialData?: Partial<CriarObrigacaoPayload>;
	onSave: (data: CriarObrigacaoPayload) => void;
	onSaveObrigacao?: (data: TObrigacao) => void;
	onClose?: () => void;
	isEdit?: boolean;
	empresaId: number | string;
	headerTitle?: string;
	status?: string;
	empresaNome?: string;
	obrigacaoId?: number | string;
	onObrigacaoChange: (obrigacaoAtualizada: TObrigacao) => void;
	obrigacao?: TObrigacao;
	onAttachClick?: () => void;
	onAtualizar?: () => void;
}

const FormObrigacoes: React.FC<FormObrigacoesProps> = ({
	initialData = {},
	onClose,
	isEdit = false,
	empresaId,
	obrigacaoId,
	empresaNome,
	onObrigacaoChange,
	onSaveObrigacao,
	obrigacao,
	onAtualizar,
}) => {
	const _obrigacoesService = new ObrigacoesService();
	const { usuario } = useGovernancaContext();
	const [isModalAlterarVencimento, setIsModalAlterarVencimento] = useState(false);
	const [isModalConluirObrigacao, setIsModalConcluirObrigacao] = useState(false);
	const [isModalCancelarObrigacao, setIsModalCancelarObrigacao] = useState(false);
	const [isModalLogs, setIsModalLogs] = useState(false);
	const [isModalAnexosVisible, setIsModalAnexosVisible] = useState(false);
	const [anexoData, setAnexoData] = useState<CriarAnexoPayload>({ arquivo: null, descricao: '' });

	const handleAnexoSubmit = async () => {
		try {
			if (anexoData.arquivo && obrigacao?.id) {
				const res = await _obrigacoesService.criarAnexoObrigacao(
					empresaId,
					obrigacao.id,
					anexoData.arquivo,
					anexoData.descricao,
					'usuarioAtivo',
				);

				if (res.isSuccess) {
					setAlert({ type: 'success', content: 'Anexo criado com sucesso.', title: 'Sucesso!' });
					setAnexoData({ arquivo: null, descricao: '' });

					if (onAtualizar) {
						onAtualizar();
					}
				} else {
					setAlert({ type: 'error', content: 'Erro ao enviar o anexo.' });
				}
			} else {
				setAlert({ type: 'error', content: 'Nenhum arquivo foi selecionado.' });
			}
		} catch (error) {
			setAlert({ type: 'error', content: 'Ocorreu um erro ao criar o anexo. Tente novamente mais tarde.' });
		}
	};

	const handleAnexoChange = (field: keyof CriarAnexoPayload, value: unknown) => {
		setAnexoData((prev) => ({ ...prev, [field]: value }));
	};

	const handleAttachClick = async () => {
		if (isModalAnexosVisible) return;

		setIsModalAnexosVisible(true);

		try {
			const anexosAtualizados = await _obrigacoesService.getListaAnexos(empresaId, obrigacaoId as number);

			setAnexoData((prevState) => ({
				...prevState,
				anexos: anexosAtualizados,
			}));
		} catch (error) {
			console.error('Erro ao buscar anexos:', error);
			setAlert({ type: 'error', content: 'Erro ao buscar anexos.' });
		}
	};

	const handleCloseAnexosModal = () => {
		setIsModalAnexosVisible(false);
		setAnexoData({ arquivo: null, descricao: '' });
	};

	const formSchema = z
		.object({
			id: z.number().optional().nullable(),
			frequenciaSelecionada: z
				.number()
				.nullable()
				.refine((value) => value !== null && Number.isInteger(value), {
					message: 'Frequência de envio obrigatório',
				}),

			antecedenciaSelecionada: z
				.number()
				.nullable()
				.refine((value) => value !== null && Number.isInteger(value), {
					message: 'Antecedência de envio obrigatória',
				}),
			frequenciaVencidaSelecionada: z
				.number()
				.nullable()
				.refine((value) => value !== null && Number.isInteger(value), {
					message: 'Frequência de envio após vencimento obrigatória',
				}),

			// Calculo Periodicidade
			tipoVencimento: isEdit
				? z.number().optional().nullable()
				: z
						.number()
						.nullable()
						.refine((value) => value !== null && Number.isInteger(value), {
							message: 'Tipo de vencimento obrigatório',
						}),

			dataVencimento: z.string().min(1, 'Data de vencimento obrigatória'),

			periodicidadeSelecionada: isEdit
				? z.number().optional().nullable()
				: z
						.number()
						.nullable()
						.refine((value) => value !== null && Number.isInteger(value), {
							message: 'Periodicidade obrigatória',
						}),
			diaUtil: z.string().optional(),

			nome: z.string().min(1, 'Descrição obrigatória'),
			acompanhamento: z.string().optional(),
			fundamentacaoLegal: z.string().optional(),
			datas: z.string().optional(),
			prazoLegal: z.string().optional(),
			temporalidade: z.string().optional(),
			formaCumprimento: z.string().optional(),
			responsavelPrincipal: z.number().min(1, 'Repsonsável principal obrigatório'),
			aprovadorPrincipal: z.number().min(1, 'Aprovador principal obrigatório'),
			responsaveisSelecionados: z.array(z.number()).optional(),
			aprovadoresSelecionados: z.array(z.number()).optional(),
			ehLegal: z.boolean().optional(),
			periodoSelecionado: z.number().nullable().optional(),
			casoNaoUtilSelecionado: z.number().nullable(),
			listaEmailSelecionados: z.array(z.number()).optional(),
			listaEmailSomenteAtrasoSelecionados: z.array(z.number()).optional(),

			//ViewModel
			justificativaAtraso: z.string().nullable().optional(),
			justificativaImplementacao: z.string().nullable().optional(),
			justificativaNaoAprovacao: z.string().optional(),
			pontoMelhoria: z.string().nullable().optional(),
			reaprovada: z.boolean().optional(),
			obrigacaoConcluida: z.boolean().optional(),
			emAtraso: z.boolean().optional(),
			emAtrasoDataFinalSolicitada: z.boolean().optional(),
			podeConcluir: z.boolean().optional(),
			origemSelecionada: z.number().nullable().optional(),
			codigo: z.number().nullable().optional(),

			// Conclusao
			observacao: z.string().nullable().optional(),
			dataConclusaoObrigacao: z.date().nullable().optional(),

			// Cancelamento
			solicitacaoCancelamento: z.boolean().optional().nullable(),
			pedidoDeCancelamentoClicado: z.boolean().optional().nullable(),
			dataHoje: z.date(),

			// Alteração data final
			pedidoAlteracaoDataFinal: z.boolean().nullable().optional(),
			novaDataFinal: z.string().nullable().optional(),
			justificativaDataAlterada: z.string().optional(),
			solicitaAlterarDataFinal: z.boolean().optional().nullable(),
			aprovarAlteracaoDataFinal: z.number().optional().nullable(),
			justificativaNaoAprovacaoDataAlterada: z.string().nullable().optional(),
			dataFinalAnterior: z.string().optional(),
			estadoStatusAlterarData: z.number().optional().nullable(),

			// Aprovação
			aprovada: z.number().optional(),
			JustificativaNaoAprovacao: z.string().optional(),

			// Cancelamento
			statusAprovarCancelamento: z.number().optional(),
			justificativaNaoAprovarCancelamento: z.string().optional(),
			estadoStatusAprovada: z.number().optional(),
			justificativaCancelar: z.string().nullable().optional(),
			bCancelar: z.boolean().nullable().optional(),
			dataCancelamento: z.date().optional().nullable(),

			estadoStatusCancelamento: z.number().optional(),
		})
		.superRefine((data, ctx) => {
			if (data.tipoVencimento === 1 && (data.diaUtil === '' || data.diaUtil === null || data.diaUtil === undefined)) {
				ctx.addIssue({
					path: ['diaUtil'],
					message: 'Dia útil obrigatório',
					code: z.ZodIssueCode.custom,
				});
			}
			if (
				!isEdit &&
				data.periodicidadeSelecionada !== 0 &&
				data.periodicidadeSelecionada !== null &&
				(data.periodoSelecionado === null || data.periodoSelecionado === undefined)
			) {
				ctx.addIssue({
					path: ['periodoSelecionado'],
					message: 'Dia útil obrigatório',
					code: z.ZodIssueCode.custom,
				});
			}
		});

	const [alert, setAlert] = useState<{ type: 'success' | 'error'; content: string; title?: string } | null>(null);

	const createDefaultValues = (initialData: Partial<CriarObrigacaoPayload>): FormValues => {
		const values: any = {
			id: initialData.id ?? null,
			nome: initialData.nome ?? '',
			aprovadorPrincipal: initialData.aprovadorPrincipal ?? 0,
			responsavelPrincipal: initialData.responsavelPrincipal ?? 0,
			aprovadoresSelecionados: initialData.aprovadoresSelecionados ?? [],
			responsaveisSelecionados: initialData.responsaveisSelecionados ?? [],
			acompanhamento: initialData.acompanhamento ?? '',
			fundamentacaoLegal: initialData.fundamentacaoLegal ?? '',
			datas: initialData.datas ?? '',
			prazoLegal: initialData.prazoLegal ?? '',
			temporalidade: initialData.temporalidade ?? '',
			ehLegal: initialData.ehLegal,
			formaCumprimento: initialData.formaCumprimento ?? '',
			listaEmailSelecionados: initialData.listaEmailSelecionados ?? [],
			listaEmailSomenteAtrasoSelecionados: initialData.listaEmailSomenteAtrasoSelecionados ?? [],
			tipoVencimento: initialData.tipoVencimento ?? null,

			diaUtil: initialData.diaUtil,
			dataVencimento: initialData.dataVencimento ?? '',
			origemSelecionada: initialData.origemSelecionada,
			codigo: initialData.codigo,

			frequenciaSelecionada: initialData.frequenciaSelecionada ?? null,
			antecedenciaSelecionada: initialData.antecedenciaSelecionada ?? null,
			frequenciaVencidaSelecionada: initialData.frequenciaVencidaSelecionada ?? null,
			periodicidadeSelecionada: initialData.periodoSelecionado ?? null,

			// Campos adicionais
			justificativaAtraso: initialData.justificativaAtraso ?? '',
			justificativaCancelar: initialData.justificativaCancelar ?? '',
			justificativaImplementacao: initialData.justificativaImplementacao ?? '',
			justificativaNaoAprovacao: initialData.justificativaNaoAprovacao ?? '',
			justificativaNaoAprovacaoDataAlterada: initialData.justificativaNaoAprovacaoDataAlterada ?? '',
			justificativaNaoAprovarCancelamento: initialData.justificativaNaoAprovarCancelamento ?? '',
			pontoMelhoria: initialData.pontoMelhoria ?? '',
			reaprovada: initialData.reaprovada ?? false,
			obrigacaoConcluida: initialData.obrigacaoConcluida ?? false,
			emAtraso: initialData.emAtraso ?? false,
			emAtrasoDataFinalSolicitada: initialData.emAtrasoDataFinalSolicitada ?? false,
			podeConcluir: initialData.podeConcluir ?? false,
			dataConclusaoObrigacao: new Date(),
			dataHoje: new Date(),

			// Alteração data final
			observacao: initialData.observacao,
			dataFinalAnterior: initialData.dataVencimento,
			novaDataFinal: initialData.novaDataFinal,
			justificativaDataAlterada: initialData.justificativaDataAlterada || '',
			estadoStatusAprovada: initialData.estadoStatusAprovada,
			estadoStatusCancelamento: initialData.estadoStatusCancelamento || 0,
			estadoStatusAlterarData: initialData.estadoStatusAlterarData || 0,
			periodoSelecionado: initialData.periodoSelecionado,
			casoNaoUtilSelecionado: initialData.casoNaoUtilSelecionado || 2,
		};

		if (values.periodicidadeSelecionada === null && isEdit) {
			delete values.periodicidadeSelecionada;
		}

		return values;
	};

	const defaultValues = createDefaultValues(initialData);

	type FormValues = z.infer<typeof formSchema>;
	const [loading, setLoading] = useState(false);
	const ehAprovadorPrincipal = verificarAprovadorPrincipal(
		defaultValues.aprovadorPrincipal,
		defaultValues.aprovadoresSelecionados ?? [],
	);
	const ehResponsavelPrincipal = verificarResponsavelPrincipal(
		defaultValues.responsavelPrincipal,
		defaultValues.responsaveisSelecionados ?? [],
	);

	const handleOpenAlterarVencimentoModal = () => {
		setIsModalAlterarVencimento(true);
	};

	const handleOpenConcluirObrigacaoModal = () => {
		setIsModalConcluirObrigacao(true);
	};

	const handleOpenCancelarObrigacaoModal = () => {
		setIsModalCancelarObrigacao(true);
	};
	const [data, setData] = useState<CriarObrigacaoPayload>({
		nome: initialData.nome || '',
		aprovadorPrincipal: initialData.aprovadorPrincipal || 0,
		responsavelPrincipal: initialData.responsavelPrincipal || 0,
		aprovadoresSelecionados: initialData.aprovadoresSelecionados || [],
		responsaveisSelecionados: initialData.responsaveisSelecionados || [],
		acompanhamento: initialData.acompanhamento || '',
		fundamentacaoLegal: initialData.fundamentacaoLegal || '',
		datas: initialData.datas || '',
		prazoLegal: initialData.prazoLegal || '',
		temporalidade: initialData.temporalidade || '',
		ehLegal: initialData.ehLegal,
		formaCumprimento: initialData.formaCumprimento || '',
		listaEmailSelecionados: initialData.listaEmailSelecionados || [],
		listaEmailSomenteAtrasoSelecionados: initialData.listaEmailSomenteAtrasoSelecionados || [],
		origemSelecionada: initialData.origemSelecionada,

		//E-mail
		antecedenciaSelecionada: initialData.antecedenciaSelecionada,
		frequenciaVencidaSelecionada: initialData.frequenciaVencidaSelecionada,

		diaUtil: initialData.diaUtil,
		dataVencimento: initialData.dataVencimento,
		dataFinal: initialData.dataFinal,

		// Campos adicionais
		justificativaAtraso: initialData.justificativaAtraso || '',
		justificativaCancelar: initialData.justificativaCancelar || '',
		justificativaImplementacao: initialData.justificativaImplementacao || '',
		justificativaNaoAprovacao: initialData.justificativaNaoAprovacao || '',
		justificativaNaoAprovacaoDataAlterada: initialData.justificativaNaoAprovacaoDataAlterada || '',
		justificativaNaoAprovarCancelamento: initialData.justificativaNaoAprovarCancelamento || '',
		pontoMelhoria: initialData.pontoMelhoria || '',
		reaprovada: initialData.reaprovada || false,
		obrigacaoConcluida: initialData.obrigacaoConcluida || false,
		emAtraso: initialData.emAtraso || false,
		emAtrasoDataFinalSolicitada: initialData.emAtrasoDataFinalSolicitada || false,
		podeConcluir: initialData.podeConcluir || false,
		novaDataFinal: initialData.novaDataFinal,
		justificativaDataAlterada: initialData.justificativaDataAlterada || '',

		// Conclusao
		dataConclusaoObrigacao: new Date(),
		dataHoje: new Date(),
		estadoStatusAprovada: initialData.estadoStatusAprovada,
		estadoStatusCancelamento: initialData.estadoStatusCancelamento || 0,
		estadoStatusAlterarData: initialData.estadoStatusAlterarData || null,
	});

	const {
		control,
		setValue,
		getValues,
		formState: { errors },
		handleSubmit,
		unregister,
	} = useForm<FormValues>({
		resolver: zodResolver(formSchema),
		defaultValues,
	});

	const onSubmit = async (data: FormValues) => {
		setLoading(true);

		if (!isEdit) {
			if (!Array.isArray(data.aprovadoresSelecionados)) {
				data.aprovadoresSelecionados = data.aprovadoresSelecionados ? [data.aprovadoresSelecionados] : [];
			}
			if (!Array.isArray(data.responsaveisSelecionados)) {
				data.responsaveisSelecionados = data.responsaveisSelecionados ? [data.responsaveisSelecionados] : [];
			}

			if (!Array.isArray(data.listaEmailSelecionados)) {
				data.listaEmailSelecionados = data.listaEmailSelecionados ? [data.listaEmailSelecionados] : [];
			}

			if (!Array.isArray(data.listaEmailSomenteAtrasoSelecionados)) {
				data.listaEmailSomenteAtrasoSelecionados = data.listaEmailSomenteAtrasoSelecionados
					? [data.listaEmailSomenteAtrasoSelecionados]
					: [];
			}

			data.aprovadorPrincipal = data.aprovadorPrincipal ?? 0;
			data.responsavelPrincipal = data.responsavelPrincipal ?? 0;

			try {
				await _obrigacoesService.criarObrigacao(empresaId, data).then((res) => {
					onObrigacaoChange(res.data);
					onSaveObrigacao?.(res.data);
					setAlert({ type: 'success', content: 'Obrigação criada com sucesso!', title: 'Sucesso' });

					if (onClose) {
						onClose();
					}
				});
			} catch (error) {
				setAlert({ type: 'error', content: 'Erro ao salvar a obrigação. Tente novamente.', title: 'Erro' });
			} finally {
				setLoading(false);
			}
		}

		if (isEdit) {
			if (!Array.isArray(data.aprovadoresSelecionados)) {
				data.aprovadoresSelecionados = data.aprovadoresSelecionados ? [data.aprovadoresSelecionados] : [];
			}
			if (!Array.isArray(data.responsaveisSelecionados)) {
				data.responsaveisSelecionados = data.responsaveisSelecionados ? [data.responsaveisSelecionados] : [];
			}

			if (!Array.isArray(data.listaEmailSelecionados)) {
				data.listaEmailSelecionados = data.listaEmailSelecionados ? [data.listaEmailSelecionados] : [];
			}

			if (!Array.isArray(data.listaEmailSomenteAtrasoSelecionados)) {
				data.listaEmailSomenteAtrasoSelecionados = data.listaEmailSomenteAtrasoSelecionados
					? [data.listaEmailSomenteAtrasoSelecionados]
					: [];
			}

			data.aprovadorPrincipal = data.aprovadorPrincipal ?? 0;
			data.responsavelPrincipal = data.responsavelPrincipal ?? 0;

			try {
				if (obrigacaoId) {
					await _obrigacoesService
						.atualizarObrigacao(empresaId, obrigacaoId, data as CriarObrigacaoPayload)
						.then((res) => {
							onObrigacaoChange(res.data as TObrigacao);

							setAlert({ type: 'success', content: 'Obrigação editada com sucesso!', title: 'Sucesso' });

							if (onClose) {
								onClose();
							}
						});
				}
			} catch (error) {
				setAlert({ type: 'error', content: 'Erro ao salvar a obrigação. Tente novamente!', title: 'Erro' });
			} finally {
				setLoading(false);
			}
		}
	};

	useEffect(() => {
		setData({
			nome: initialData.nome || '',
			aprovadorPrincipal: initialData.aprovadorPrincipal || 0,
			responsavelPrincipal: initialData.responsavelPrincipal || 0,
			aprovadoresSelecionados: initialData.aprovadoresSelecionados || [],
			responsaveisSelecionados: initialData.responsaveisSelecionados || [],
			acompanhamento: initialData.acompanhamento || '',
			fundamentacaoLegal: initialData.fundamentacaoLegal || '',
			datas: initialData.datas || '',
			prazoLegal: initialData.prazoLegal || '',
			temporalidade: initialData.temporalidade || '',
			formaCumprimento: initialData.formaCumprimento || '',
			listaEmailSelecionados: initialData.listaEmailSelecionados || [],
			listaEmailSomenteAtrasoSelecionados: initialData.listaEmailSomenteAtrasoSelecionados || [],
			origemSelecionada: initialData.origemSelecionada,

			//E-mail
			antecedenciaSelecionada: initialData.antecedenciaSelecionada,
			frequenciaVencidaSelecionada: initialData.frequenciaVencidaSelecionada,

			ehLegal: initialData.ehLegal,
			diaUtil: initialData.diaUtil,
			dataVencimento: initialData.dataVencimento,
			dataFinal: initialData.dataFinal,

			// Campos adicionais
			justificativaAtraso: initialData.justificativaAtraso || '',
			justificativaCancelar: initialData.justificativaCancelar || '',
			justificativaImplementacao: initialData.justificativaImplementacao || '',
			justificativaNaoAprovacao: initialData.justificativaNaoAprovacao || '',
			justificativaNaoAprovacaoDataAlterada: initialData.justificativaNaoAprovacaoDataAlterada || '',
			justificativaNaoAprovarCancelamento: initialData.justificativaNaoAprovarCancelamento || '',
			pontoMelhoria: initialData.pontoMelhoria || '',
			reaprovada: initialData.reaprovada || false,
			obrigacaoConcluida: initialData.obrigacaoConcluida || false,
			emAtraso: initialData.emAtraso || false,
			emAtrasoDataFinalSolicitada: initialData.emAtrasoDataFinalSolicitada || false,
			podeConcluir: initialData.podeConcluir || false,
			dataConclusaoObrigacao: new Date(),
			dataHoje: new Date(),
			novaDataFinal: initialData.novaDataFinal,
			justificativaDataAlterada: initialData.justificativaDataAlterada || '',
			estadoStatusAprovada: initialData.estadoStatusAprovada,
			estadoStatusAlterarData: initialData.estadoStatusAlterarData || 0,
			estadoStatusCancelamento: initialData.estadoStatusCancelamento || 0,
		});
	}, [initialData]);

	const handleChange = (field: string, value: unknown) => {
		setData({
			...data,
			[field]: value,
		});
	};
	
	const atualizarAnexos = () => {
		console.log('Anexos atualizados');
	};

	return (
		<form onSubmit={(ev) => ev.preventDefault()}>
			<CustomHeader
				isEdit={isEdit}
				empresaNome={empresaNome || ''}
				dataVencimento={data.dataVencimento?.toString() || ''}
				obrigacao={initialData}
				isModalLogs={isModalLogs}
				openAlterarVencimentoModal={handleOpenAlterarVencimentoModal}
				openConcluirObrigacaoModal={handleOpenConcluirObrigacaoModal}
				openModalLogs={setIsModalLogs}
				usuarioAprovador={ehAprovadorPrincipal}
				usuarioResponsavel={ehResponsavelPrincipal}
			/>

			<Box sx={{ padding: 2, margin: '30px 0 0 0' }}>
				<Grid container spacing={2}>
					<Grid item xs={12} sm={12} md={7} lg={6} xl={6}>
						<GerenciarObrigacao
							data={data}
							setValue={setValue}
							getValues={getValues}
							isEdit={isEdit}
							empresaId={empresaId}
							control={control}
							errors={errors}
							onChange={handleChange}
							usuarioAprovador={ehAprovadorPrincipal}
							usuarioResponsavel={ehResponsavelPrincipal}
						/>
					</Grid>
					<Grid item xs={12} sm={12} md={5} lg={6} xl={6}>
						<Grid container spacing={2}>
							{!isEdit && (
								<Grid item xs={12}>
									<CalculoPeriodicidade control={control} errors={errors} setValue={setValue} getValues={getValues} />
								</Grid>
							)}

							<Grid item xs={12}>
								<EnvioEmails
									control={control}
									errors={errors}
									empresaId={empresaId}
									setValue={setValue}
									getValues={getValues}
									usuarioAprovador={ehAprovadorPrincipal}
									usuarioResponsavel={ehResponsavelPrincipal}
									isEdit={isEdit}
								/>
								<LogsModal
									isModalLogs={isModalLogs}
									setIsModalLogs={setIsModalLogs}
									empresaId={empresaId}
									obrigacaoId={initialData.id}
								/>
							</Grid>

							{isEdit && (
								<Grid item xs={12}>
									<AcoesAnexo
										empresaId={empresaId}
										setValue={setValue}
										getValues={getValues}
										control={control}
										unregister={unregister}
										usuarioAprovador={ehAprovadorPrincipal}
										usuarioResponsavel={ehResponsavelPrincipal}
										aprovadoresSelecionados={data.aprovadoresSelecionados || []}
										responsaveisSelecionados={data.responsaveisSelecionados || []}
										openConcluirObrigacaoModal={handleOpenConcluirObrigacaoModal}
										openAlterarVencimentoModal={handleOpenAlterarVencimentoModal}
										openCancelarObrigacaoModal={handleOpenCancelarObrigacaoModal}
										data={data}
										onAttachClick={handleAttachClick}
									/>
								</Grid>
							)}
						</Grid>
					</Grid>
				</Grid>

				<Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '1rem', gap: '20px' }}>
					<Button
						style={{ color: '#041F1F', backgroundColor: '#FFDAD6' }}
						type='filled-tonal'
						variant='error'
						onClick={(ev) => {
							ev.preventDefault();
							onClose?.();
						}}
						itemType='button'
						disabled={loading}
					>
						Cancelar
					</Button>
					<Button
						style={{
							backgroundColor: '#CCE8E7',
							color: '#041F1F',
						}}
						variant='save'
						loading={loading}
						disabled={
							obrigacaoEstaConcluida(data.estadoStatusAprovada ?? 0) ||
							(usuarioEhAdmin(usuario?.funcao, ehResponsavelPrincipal) && isEdit)
						}
						onClick={handleSubmit(onSubmit)}
					>
						Salvar
					</Button>
				</Box>
			</Box>

			<ModalConcluirObrigacao
				isVisible={isModalConluirObrigacao}
				onClose={() => setIsModalConcluirObrigacao(false)}
				getValues={getValues}
				setValue={setValue}
				control={control}
				unregister={unregister}
				usuarioAprovador={ehAprovadorPrincipal}
				usuarioResponsavel={ehResponsavelPrincipal}
				handleSubmit={handleSubmit(onSubmit)}
			/>

			<ModalAlterarData
				isVisible={isModalAlterarVencimento}
				onClose={() => setIsModalAlterarVencimento(false)}
				getValues={getValues}
				setValue={setValue}
				control={control}
				unregister={unregister}
				usuarioAprovador={ehAprovadorPrincipal}
				usuarioResponsavel={ehResponsavelPrincipal}
				handleSubmit={handleSubmit(onSubmit)}
			/>

			<ModalCancelarObrigacao
				isVisible={isModalCancelarObrigacao}
				onClose={() => setIsModalCancelarObrigacao(false)}
				getValues={getValues}
				setValue={setValue}
				control={control}
				unregister={unregister}
				usuarioAprovador={ehAprovadorPrincipal}
				usuarioResponsavel={ehResponsavelPrincipal}
				handleSubmit={handleSubmit(onSubmit)}
			/>
			<Anexos
				empresaId={Number(empresaId)}
				data={anexoData}
				obrigacao={obrigacao || ({} as TObrigacao)}
				onChange={handleAnexoChange}
				onSave={handleAnexoSubmit}
				onAtualizar={onAtualizar ?? atualizarAnexos}
				isVisible={isModalAnexosVisible}
				onClose={handleCloseAnexosModal}
			/>

			{alert && <CustomAlert type={alert.type} content={alert.content} title={alert.title} />}
		</form>
	);
};

export default FormObrigacoes;
