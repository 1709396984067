import React, { useEffect, useState } from 'react';
import { Button, Col, Flex, Icons, Typography } from '@jcm/design-system';
import FormObrigacoes from '../../FormObrigacoes/FormObrigacoes';
import ModalContainer from '../../../../components/modalContainer/ModalContainer';
import { CriarObrigacaoPayload, TObrigacao } from '../../../../ts/types/Obrigacoes';
import { transformObrigacaoToPayload } from '../../../../ts/types/serializers';
import style from './index.module.scss';
import { Popover } from 'antd';
import ModalConcluirObrigacao from '../../../../components/obrigacoes/ModalConcluirObrigacao/ModalConcluirObrigacao';
import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import ObrigacoesService from '../../../../services/Obrigacoes/Obrigacoes.service';
import CustomAlert from '../../../../../../../shared/components/customAlert/customAlert';
import {
	verificarAprovadorPrincipal,
	verificarResponsavelPrincipal,
} from '../../../../utils/determinarAcessoObrigacoes';
import { EditarObrigacaoZodSchema } from '../../../../zod/validations/EditarObrigacaoZodSchema';

interface AcoesProps {
	onVerEtapasClick: () => void;
	onAdicionarEtapasClick?: () => void;
	disabled?: boolean;
	obrigacao: TObrigacao;
	empresaId: string | number;
	concluida: boolean;
	onObrigacaoChange: (obrigacaoAtualizada: TObrigacao) => void;
	onAttachClick: () => void;
}

const Acoes: React.FC<AcoesProps> = ({
	onVerEtapasClick,
	onAdicionarEtapasClick,
	disabled,
	obrigacao,
	empresaId,
	onObrigacaoChange,
	onAttachClick,
}) => {
	const [isModalVisible, setModalVisible] = useState(false);
	const [modalContent, setModalContent] = useState<React.ReactNode>(null);
	const [visibilidadeModalObrigacao, setVisibilidadeModalObrigacao] = useState(false);
	const obrigacoesService = new ObrigacoesService();
	const [loading, setLoading] = useState(false);
	const [alert, setAlert] = useState<{ type: 'success' | 'error'; content: string; title?: string } | null>(null);

	const obrigacaoSerializada = transformObrigacaoToPayload(obrigacao);

	const ehAprovadorPrincipal = verificarAprovadorPrincipal(
		obrigacaoSerializada.aprovadorPrincipal ?? 0,
		obrigacaoSerializada.aprovadoresSelecionados ?? [],
	);
	const ehResponsavelPrincipal = verificarResponsavelPrincipal(
		obrigacaoSerializada.responsavelPrincipal ?? 0,
		obrigacaoSerializada.responsaveisSelecionados ?? [],
	);

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const createDefaultValues = (initialData: any) => ({
		...initialData,
		aprovadorPrincipal: initialData.aprovadorPrincipal ?? 0,
		responsavelPrincipal: initialData.responsavelPrincipal ?? 0,
		listaEmailSelecionados: initialData.listaEmailSelecionados ?? [],
		listaEmailSomenteAtrasoSelecionados: initialData.listaEmailSomenteAtrasoSelecionados ?? [],
		dataConclusaoObrigacao: new Date(),
		dataHoje: new Date(),
	});

	const defaultValues = createDefaultValues(obrigacaoSerializada);
	type FormValues = z.infer<typeof EditarObrigacaoZodSchema>;

	const { control, setValue, getValues, unregister, reset } = useForm<FormValues>({
		resolver: zodResolver(EditarObrigacaoZodSchema),
		defaultValues,
	});

	useEffect(() => {
		reset(createDefaultValues(obrigacaoSerializada));
	}, [obrigacao, reset]);

	const handleSubmitObrigacao = async (data: CriarObrigacaoPayload) => {
		setLoading(true);

		try {
			const response = await obrigacoesService.atualizarObrigacao(empresaId, obrigacao.id, data);
			onObrigacaoChange(response.data as TObrigacao);

			setAlert({ type: 'success', content: 'Obrigação concluída com sucesso!', title: 'Sucesso' });
		} catch (error) {
			setAlert({ type: 'error', content: 'Erro ao concluir a obrigação. Tente novamente!', title: 'Erro' });
		} finally {
			setLoading(false);
		}
	};

	const handleOpenModal = () => {
		setModalContent(
			<FormObrigacoes
				obrigacao={obrigacao}
				isEdit={true}
				initialData={obrigacaoSerializada}
				empresaId={empresaId}
				obrigacaoId={obrigacao.id}
				onClose={handleCloseModal}
				onSave={handleCloseModal}
				onObrigacaoChange={onObrigacaoChange}
				onAttachClick={onAttachClick}
			/>,
		);
		setModalVisible(true);
	};

	const handleCloseModal = () => setModalVisible(false);

	return (
		<Col span={24}>
			<Flex justify='space-between' align='middle'>
				<Flex gap={8}>
					<Button variant='default' type='outlined' onClick={handleOpenModal}>
						Editar
					</Button>

					{((!obrigacao.concluida && ehResponsavelPrincipal) || (obrigacao.concluida && ehAprovadorPrincipal)) &&
						obrigacao.estadoStatusAprovada !== 2 && (
							<Button
								type='filled'
								variant='default'
								onClick={() => {
									setVisibilidadeModalObrigacao(true);
								}}
								loading={loading}
							>
								{ehAprovadorPrincipal && ehResponsavelPrincipal && obrigacao.estadoStatusAprovada === 1
									? 'Analisar a conclusão da obrigação'
									: ehAprovadorPrincipal && !ehResponsavelPrincipal
										? 'Analisar a conclusão da obrigação'
										: ehAprovadorPrincipal && ehResponsavelPrincipal
											? 'Concluir Obrigação'
											: 'Concluir obrigação'}
							</Button>
						)}
				</Flex>

				<Flex align='center' gap={16} className={style.textColor}>
					<Popover placement='right' content='Em desenvolvimento'>
						<Flex align='center' onClick={onAdicionarEtapasClick} className={style.cursorPointer}>
							<Typography.Title size='medium'>Adicionar etapas</Typography.Title>
							<Icons.Add className={style.iconSize} />
						</Flex>
					</Popover>

					<Flex
						align='center'
						onClick={!disabled ? onVerEtapasClick : undefined}
						className={disabled ? style.disabledCursor : style.pointer}
					>
						<Typography.Title size='medium'>Ver etapas</Typography.Title>
						<Icons.KeyboardArrowDown
							variant='outlined'
							className={`${style.iconSize} ${disabled ? style.disabledColor : style.defaultColor}`}
						/>
					</Flex>
				</Flex>
			</Flex>

			<ModalContainer className={style.modalFormEditContainer} isVisible={isModalVisible} onClose={handleCloseModal}>
				{modalContent}
			</ModalContainer>

			<ModalConcluirObrigacao
				isVisible={visibilidadeModalObrigacao}
				onClose={() => setVisibilidadeModalObrigacao(false)}
				getValues={getValues}
				setValue={setValue}
				control={control}
				unregister={unregister}
				usuarioAprovador={ehAprovadorPrincipal}
				usuarioResponsavel={ehResponsavelPrincipal}
				handleSubmit={handleSubmitObrigacao}
			/>

			{alert && <CustomAlert type={alert.type} content={alert.content} title={alert.title} />}
		</Col>
	);
};

export default Acoes;
